import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import {
  ELaunchpointDynamicForm,
  ILaunchpointDynamicForm,
  ILaunchpointDynamicFormGroupController,
  ILaunchpointDynamicFormGroupParamsCreate,
  ILaunchpointDynamicFormGroupParamsDelete,
  ILaunchpointDynamicFormGroupParamsReorder,
  ILaunchpointDynamicFormGroupParamsUpdate,
} from '@launchpoint-app/types';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig, LaunchpointSecurityAccountIdHeaderServiceV2 } from '@launchpoint/core-client';
import { configureURL, ELPDefaultActions } from '@launchpoint/core-types';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class LaunchpointDynamicFormGroupV1Service
  extends LaunchpointSecurityAccountIdHeaderServiceV2
  implements ILaunchpointDynamicFormGroupController
{
  http = inject(HttpClient);
  API_URL = '/api/backend/';

  constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _CoreAuthConfig: ICoreAuthConfig) {
    super();

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _CoreAuthConfig.auth?.base_url,
      version: 1,
      route: ELaunchpointDynamicForm.GROUPS,
    });
  }

  public add(params: ILaunchpointDynamicFormGroupParamsCreate): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointDynamicForm>(this.API_URL, params, { headers });
      })
    );
  }

  public update(params: ILaunchpointDynamicFormGroupParamsUpdate): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicForm>(this.API_URL, params, { headers });
      })
    );
  }

  public reorder(params: ILaunchpointDynamicFormGroupParamsReorder): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicForm>(this.API_URL + '/' + ELPDefaultActions.REORDER, params, { headers });
      })
    );
  }

  public remove(params: ILaunchpointDynamicFormGroupParamsDelete): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicForm>(this.API_URL + '/' + ELPDefaultActions.DELETE, params, { headers });
      })
    );
  }
}
