import { computed, inject, Injectable, signal } from '@angular/core';
import { LaunchpointBlogPublicHTTPService } from '@launchpoint/core-client';
import { ICoreBlog, ICoreBlogParamsGetOne, ICoreBlogSearchQuery, IQuerySort } from '@launchpoint/core-types';
import { first } from 'rxjs';

@Injectable()
export class LaunchpointBlogService {
  _BlogHTTPService = inject(LaunchpointBlogPublicHTTPService);

  all_blogs = signal<ICoreBlog[]>([]);
  featured_blog = signal<ICoreBlog>(null);
  selected_blog = signal<ICoreBlog | null>(null);
  blogsPerPage = signal(6);
  currentPage = signal(1);
  selectedCategory = signal('all');

  loading = signal(false);

  blogCategories = computed(() => {
    const categories = this.all_blogs().map((n) => n.category);
    return [...new Set(categories)]; // Use Set to remove duplicates and spread it back into an array
  });

  private listQuery: ICoreBlogSearchQuery = { live: true };
  private featuredQuery: ICoreBlogSearchQuery = { live: true, featured: true };
  private querySort: IQuerySort = { created_at: -1 };

  getBlogs() {
    this.loading.set(true);
    this._BlogHTTPService
      .searchPublic({ pagination: { limit: 20, skip: 0 }, query: this.listQuery, querySort: this.querySort })
      .pipe(first())
      .subscribe({
        next: (res) => {
          this.loading.set(false);
          if (res.data.length > 0) {
            this.all_blogs.set(res.data);
          }
        },
        error: (error) => {
          this.loading.set(false);
          console.log({ error });
        },
      });
  }

  getFeatured() {
    this.loading.set(true);
    this._BlogHTTPService
      .searchPublic({ pagination: { limit: 1, skip: 0 }, query: this.featuredQuery, querySort: this.querySort })
      .pipe(first())
      .subscribe({
        next: (res) => {
          this.loading.set(false);
          const featured = res.data[0];
          if (res.data.length > 0) {
            this.featured_blog.set(featured);
          }
        },
        error: (error) => {
          this.loading.set(false);
          console.log({ error });
        },
      });
  }

  getBlog(params: ICoreBlogParamsGetOne) {
    // console.log({ getBlog: params });
    this.loading.set(true);
    this._BlogHTTPService
      .findOnePublic(params)
      .pipe(first())
      .subscribe({
        next: (res) => {
          this.loading.set(false);
          this.selected_blog.set(this.formatBlog(res));
        },
        error: (error) => {
          this.loading.set(false);
          console.error({ error });
        },
      });
  }

  pages = computed(() => {
    const totalPages = Math.ceil(this.all_blogs().length / this.blogsPerPage());
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
    return pageNumbers;
  });

  searchString = signal('');

  filteredBlogs = computed(() => {
    const searchRegex = new RegExp(this.searchString(), 'i'); // Create a case-insensitive regex
    return this.all_blogs().filter((f) => {
      const matchesTitle = searchRegex.test(f.title); // Test title against the regex
      const matchesCategory = this.selectedCategory() && this.selectedCategory() !== 'all' ? f.category === this.selectedCategory() : true; // Check category if a category is selected and not 'all'
      return matchesTitle && matchesCategory; // Return true if both match
    });
  });

  paginatedBlogs = computed(() => {
    const startIndex = (this.currentPage() - 1) * this.blogsPerPage();
    const endIndex = startIndex + this.blogsPerPage();
    return this.filteredBlogs().slice(startIndex, endIndex);
  });

  setSelectedBlog(title_url: string) {
    const blog = this.all_blogs().find((f) => f.title_url === title_url);
    if (blog) {
      this.selected_blog.set(blog);
    } else {
      this.selected_blog.set(null);
    }
  }

  setPage(page: number) {
    this.currentPage.set(page);
  }

  prevPage() {
    if (this.currentPage() > 1) {
      this.currentPage.set(this.currentPage() - 1);
    }
  }

  nextPage() {
    if (this.currentPage() < this.pages().length) {
      this.currentPage.set(this.currentPage() + 1);
    }
  }

  formatBlog(blog: ICoreBlog) {
    blog.comments.forEach((comment) => {
      // Sort replies in place, placing items without `created_at` last
      comment.replies.sort((a, b) => {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();
        if (!a.created_at) return 1; // `a` goes last if `created_at` is missing
        if (!b.created_at) return -1; // `b` goes last if `created_at` is missing
        return dateB - dateA; // Sort by date descending
      });
    });

    // Sort comments in place, placing items without `created_at` last
    blog.comments.sort((a, b) => {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      if (!a.created_at) return 1; // `a` goes last if `created_at` is missing
      if (!b.created_at) return -1; // `b` goes last if `created_at` is missing
      return dateB - dateA; // Sort by date descending
    });

    return blog;
  }
}
