import { IEnvironment, deepMerge } from '@launchpoint/core-client';
import { environmentBase } from './environment.base';

export const environmentLocal = deepMerge(environmentBase, <IEnvironment>{
  environmentName: 'local',
  production: false,
  appVersion: 'v8.0.27',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api/backend/',
  mediaApiUrl: 'api/media/',
  notificationsApiUrl: 'api/notifications/',
  appThemeName: '',
  appPurchaseUrl: '',
  appHTMLIntegration: '',
  appPreviewUrl: '',
  appPreviewAngularUrl: '',
  appPreviewDocsUrl: '',
  appPreviewChangelogUrl: '',
});
