// interfaces/space.interfaces.ts

export interface ICircleSpace {
  id: string;
  name: string;
  description?: string;
  type: 'basic' | 'chat' | 'event' | 'course' | 'members' | 'image';
  access: 'public' | 'private' | 'secret';
  createdAt: string;
  updatedAt: string;
}

export interface ICircleSpaceCreate {
  name: string;
  description?: string;
  type: 'basic' | 'chat' | 'event' | 'course' | 'members' | 'image';
  access: 'public' | 'private' | 'secret';
}

export interface ICircleSpaceUpdate {
  name?: string;
  description?: string;
  type?: 'basic' | 'chat' | 'event' | 'course' | 'members' | 'image';
  access?: 'public' | 'private' | 'secret';
}
