<div
  class="bg-background bg-contain bg-right bg-no-repeat py-14 text-white lg:bg-[url('./assets/img/story-logo.webp')] lg:py-40"
  style="background-size: 300px"
>
  <div class="fade-in mx-auto flex max-w-[1700px] flex-col justify-around lg:flex-row xl:px-40">
    <div class="font-oswald hidden space-y-16 text-8xl font-[600] uppercase text-white/30 lg:block">
      <p class="text-white">Rob's</p>
      <p>Story</p>
      <p>Approach</p>
      <p>& Purpose</p>
    </div>
    <div class="font-oswald mb-10 px-4 text-4xl font-[600] uppercase text-white/30 lg:hidden">
      <p><span class="text-white">Rob's</span> Story</p>
      <p>Approach & Purpose</p>
    </div>
    <div class="flex flex-col justify-between space-y-10 px-4 md:w-[80%] lg:w-[500px]">
      <div>
        <h1 class="font-oswald mb-3 text-[24px] lg:text-[30px]">HOW IT STARTED</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          Inspired by my grandfather's service and the events of 9/11, I set my sights on joining the Marines. I graduated early, enlisted, and
          immersed myself in tech to build a solid foundation for the future.
        </p>
      </div>
      <div>
        <h1 class="font-oswald mb-3 text-[24px] lg:text-[30px]">A TURNING POINT</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          This photo captures one of the hardest times in my life. Living in a rundown apartment, I walked to the gym daily to coach and studied
          software late into the night. Despite the challenges, I stayed determined, knowing this moment wouldn't define my future—only fuel it.
        </p>
      </div>
      <div>
        <a
          href="/about"
          class="font-courierNew group flex items-center gap-2 text-lg font-[400] leading-relaxed transition-all duration-300 hover:-translate-y-1"
        >
          Learn More<i class="fa-regular fa-arrow-right transition-transform duration-300 group-hover:translate-x-1"></i>
        </a>
      </div>
    </div>
  </div>
</div>
