import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import {
  ELaunchpointDynamicFormResponse,
  ILaunchpointDynamicFormResponse,
  ILaunchpointDynamicFormResponseResponsesController,
  ILaunchpointDynamicFormResponseResponsesParamsCreate,
  ILaunchpointDynamicFormResponseResponsesParamsDelete,
  ILaunchpointDynamicFormResponseResponsesParamsReorder,
  ILaunchpointDynamicFormResponseResponsesParamsUpdate,
} from '@launchpoint-app/types';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig, LaunchpointSecurityAccountIdHeaderServiceV2 } from '@launchpoint/core-client';
import { configureURL, ELPDefaultActions } from '@launchpoint/core-types';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class LaunchpointDynamicFormResponseResponsesV1Service
  extends LaunchpointSecurityAccountIdHeaderServiceV2
  implements ILaunchpointDynamicFormResponseResponsesController
{
  http = inject(HttpClient);
  API_URL = '/api/backend/';

  constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _CoreAuthConfig: ICoreAuthConfig) {
    super();

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _CoreAuthConfig.auth?.base_url,
      version: 1,
      route: ELaunchpointDynamicFormResponse.RESPONSES,
    });
  }

  public add(params: ILaunchpointDynamicFormResponseResponsesParamsCreate): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointDynamicFormResponse>(this.API_URL, params, { headers });
      })
    );
  }

  public update(params: ILaunchpointDynamicFormResponseResponsesParamsUpdate): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicFormResponse>(this.API_URL, params, { headers });
      })
    );
  }

  public reorder(params: ILaunchpointDynamicFormResponseResponsesParamsReorder): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicFormResponse>(this.API_URL + '/' + ELPDefaultActions.REORDER, params, { headers });
      })
    );
  }

  public remove(params: ILaunchpointDynamicFormResponseResponsesParamsDelete): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicFormResponse>(this.API_URL + '/' + ELPDefaultActions.DELETE, params, { headers });
      })
    );
  }
}
