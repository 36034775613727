export enum EStadnickDevPages {
  HOME = '',
  ABOUT = 'about',
  PRIVACY = 'privacy-policy',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  USER_AGREEMENT = 'user-agreement',

  BOOK_STRATEGY_CALL = 'book-strategy-call',

  BLOG = 'blog',
  VETERAN_OWNED = 'veteran-owned',
  CUSTOM_DEVELOPMENT = 'custom-development',
  ACTION_PLAN = 'action-plan',
  STORE = 'store',
  // resources
  RESOURCES = 'resources',
  EVENTS = 'events',
  LESSONS = 'lessons',
  COMMUNITY = 'community',
}
