import { Directive, inject } from '@angular/core';
import { ELaunchpointFormsInputType } from '@launchpoint-app/types';
import { LaunchpointCoreClientBaseComponent } from '@launchpoint/core-client';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { updateAllLaunchpointDynamicFormPagination, updateAllLaunchpointDynamicFormQuery } from './actions/actions';
import * as LaunchpointDynamicFormSelectors from './selectors/selectors';

@Directive()
export abstract class LaunchpointDynamicFormFacadeComponent extends LaunchpointCoreClientBaseComponent {
  _Store = inject(Store);
  LaunchpointFormsInputType = ELaunchpointFormsInputType;
  LaunchpointFormsInputTypeValues = Object.values(ELaunchpointFormsInputType);
  // All LaunchpointDynamicForm
  dynamicForms$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicForm);
  pagination$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormPagination); //
  success$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormSuccess);
  loading$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormLoading);
  loaded$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormLoaded);
  error$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormError);
  query$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormQuery);
  // Single LaunchpointDynamicForm
  dynamicFormEnitity$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormEntity);
  dynamicForm$ = this._Store.select(LaunchpointDynamicFormSelectors.selectSelectedLaunchpointDynamicForm);
  dynamicFormSignal$ = this._Store.selectSignal(LaunchpointDynamicFormSelectors.selectSelectedLaunchpointDynamicForm);
  dynamicFormGroups$ = this._Store.select(LaunchpointDynamicFormSelectors.selectSelectedLaunchpointDynamicFormGroups);
  dynamicFormGroup$ = this._Store.select(LaunchpointDynamicFormSelectors.selectSelectedLaunchpointDynamicFormGroup);
  dynamicFormId$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormId);
  dynamicFormLoading$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormLoading);
  dynamicFormLoaded$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormLoaded);
  // Reports
  dynamicFormError$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormError);
  dynamicFormSuccess$ = this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormSuccess);

  async updateQuery(search: string) {
    this._Store.dispatch(updateAllLaunchpointDynamicFormQuery({ query: { search } }));
  }
  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(
      updateAllLaunchpointDynamicFormPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } })
    );
  }

  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllLaunchpointDynamicFormPagination({ pagination: { ...pagination, limit } }));
  }
}
