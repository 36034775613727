import { IEnvironment } from '@launchpoint/core-client';

/**
 * Reduce the most commonly used environment values here
 */
export const environmentBase: IEnvironment = {
  environmentName: 'local',
  production: false,
  appVersion: 'v8.0.27',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api/backend/',
  mediaApiUrl: 'api/media/',
  notificationsApiUrl: 'api/notifications/',
  appThemeName: '',
  appPurchaseUrl: '',
  appHTMLIntegration: '',
  appPreviewUrl: '',
  appPreviewAngularUrl: '',
  appPreviewDocsUrl: '',
  appPreviewChangelogUrl: '',
};
