import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import {
  IDynamicFormOpenAiParamsPostMessage,
  IDynamicFormOpenAiMessageThreadId,
  IDynamicFormOpenAiParamsGetMessageResults,
  IDynamicFormResponseOpenAiRunData,
} from '@launchpoint-app/types';
import { LaunchpointSecurityAccountIdHeaderServiceV2, APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '@launchpoint/core-client';
import { configureURL } from '@launchpoint/core-types';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class LaunchpointDynamicFormResponseAssistantV1Service extends LaunchpointSecurityAccountIdHeaderServiceV2 {
  http = inject(HttpClient);
  API_URL = '/api/backend/';

  constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _CoreAuthConfig: ICoreAuthConfig) {
    super();

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _CoreAuthConfig.auth?.base_url,
      version: 1,
      route: 'response-chat',
    });
  }

  public get(params: IDynamicFormOpenAiParamsPostMessage): Observable<IDynamicFormOpenAiMessageThreadId> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IDynamicFormOpenAiMessageThreadId>(this.API_URL, params, { headers });
      })
    );
  }

  public getMessageResponse(params: IDynamicFormOpenAiParamsGetMessageResults): Observable<IDynamicFormResponseOpenAiRunData> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IDynamicFormResponseOpenAiRunData>(this.API_URL + '/new', params, { headers });
      })
    );
  }

  /**
   * DOES NOT WORK WITH NATIVESCRIPT
   */
  getStream(prompt: string, threadId: string): Observable<string> {
    // Construct the URL with multiple query parameters
    const url = `/stream?prompt=${encodeURIComponent(prompt)}&thread_id=${encodeURIComponent(threadId)}`;
    return new Observable((observer) => {
      const eventSource = new EventSource(url);

      eventSource.onmessage = (event) => {
        observer.next(event.data);
      };

      eventSource.onerror = (error) => {
        observer.error(error);
        eventSource.close();
      };

      return () => {
        eventSource.close(); // Ensure the event source is closed when the observable is unsubscribed
      };
    });
  }
}
