import { ILaunchpointDynamicFormResponse } from '@launchpoint-app/types';
import { IHttpException } from '@launchpoint/core-types';

export interface LaunchpointDynamicFormResponseEntityState {
  dynamic_form_response_id: string;
  dynamic_form_response: ILaunchpointDynamicFormResponse | null;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message?: string | null;
  // ADD MORE CUSTOME PER FEATURE DATA HERE
}
