<div class="relative flex justify-center py-10 md:py-20">
  <div class="w-[1200px] max-w-full px-6 text-white">
    <div class="mb-20 flex flex-col items-center gap-4">
      <div class="flex w-full flex-col items-center justify-center md:max-w-[50%]">
        <h1 class="text-primary text-center text-3xl font-medium md:text-[45px]/[50px]">Go Beyond Technology</h1>
        <h2 class="my-4 text-center">
          Our mission is to fuel your success from day one. Explore courses, events, and a community designed to give
          your business an edge and drive
          lasting impact.
        </h2>
      </div>
    </div>

    <!-- CALENDAR -->
    <div class="my-10 grid grid-cols-1 gap-5 md:my-20 md:grid-cols-4 md:gap-10">
      <sd-event-calendar class="order-2 col-span-3 md:order-1 md:me-10" />

      <div class="order-1 col-span-1 flex flex-col gap-4 md:order-2">
        <div class="text-secondary text-3xl">Upcoming Events</div>
        <div class="text-sm">Join us for impactful online and in-person events, where connections and insights fuel your
          next level.</div>

        <sd-button text="View All" [routerLink]="LaunchpointPages.EVENTS" />
      </div>
    </div>

    <!-- MASTER CLASS -->
    <div class="my-10 grid grid-cols-1 gap-5 md:my-20 md:grid-cols-4 md:gap-10">
      <div class="mx-5 flex flex-col gap-4 md:col-span-1 md:mx-0">
        <div class="text-secondary text-3xl">Trainings</div>
        <div class="text-xl font-light">Level Up Your Skills</div>
        <div class="text-sm">
          Our expert-led LaunchPoint Training provides the strategies and tools you need to drive real, lasting impact
          in your business.
        </div>

        <sd-button text="View All" [routerLink]="LaunchpointPages.LESSONS" />
      </div>

      <div class="grid items-center md:col-span-3">
        <div class="no-scrollbar flex flex-col items-center gap-4 md:flex-row md:gap-10 md:overflow-x-scroll">
          @for (card of trainingCards; track card.title) {
          <sd-training-card [card]="card" />
          }
        </div>
      </div>
    </div>

    <!-- COMMUNITY -->
    <div class="my-10 flex w-full flex-col items-center justify-center md:my-20">
      <div class="flex w-full flex-col items-center justify-center gap-5 md:max-w-[75%]">
        <div class="text-secondary text-center text-3xl">Join the Launchpoint Community</div>
        <div class="text-2xl font-light">The Elite Network</div>

        <div class="text-md text-center text-gray-400">
          Step into a powerful network of driven leaders, exclusive resources, and high-impact discussions—collaborate
          with peers, find real
          solutions, and stay inspired as you push your potential further.
        </div>

        <div class="items-centr my-8 flex flex-col gap-4 text-sm">
          @for (item of items; track item) {
          <div class="flex">
            <img src="assets/images/audit-implementation/sparkle.png" class="mr-2 object-cover"
              style="width: 17px; height: 17px" />
            <div>
              <span class="font-semibold">{{ item?.title }}: </span>{{ item?.description }}
            </div>
          </div>
          }
        </div>

        <sd-button text="Join Now" [routerLink]="LaunchpointPages.COMMUNITY" />
      </div>

      <img src="assets/images/resources/community.png" class="mt-20" alt="community" />
    </div>

    <!-- APP -->
    <!-- <sd-app-links /> -->

    <div class="flex max-w-full flex-col items-center">
      <div class="mb-10 mt-20 flex flex-col items-center gap-6">
        <h1 class="text-center text-3xl">Follow Us</h1>

        <div class="flex items-center justify-center gap-5 md:gap-10">
          <a href="https://www.facebook.com/profile.php?id=61567135375134" target="_blank" class="text-md">
            <i class="fa-brands fa-facebook-f"></i>
          </a>
          <a href="https://www.instagram.com/launchpointdev/" target="_blank" class="text-md">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.youtube.com/channel/UC8MN0pb2al4tp15pjIxaTFA" target="_blank" class="text-md">
            <i class="fa-brands fa-youtube"></i>
          </a>
          <a href="https://www.linkedin.com/company/thelaunchpoints/" target="_blank" class="text-md">
            <i class="fa-brands fa-linkedin-in"></i>
          </a>
          <a href="https://x.com/LaunchpointDev" target="_blank" class="text-md">
            <i class="fa-brands fa-x-twitter"></i>
          </a>
        </div>
      </div>
    </div>

    <!-- <sd-instagram-profile class="flex justify-center" /> -->
  </div>
</div>