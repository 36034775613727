import { ICoreUser, ICoreUserAccount, ICoreUserAccountBase, ICoreUserBase, ICoreUserJWTDecorator, ICoreUserJWTToken } from '@launchpoint/core-types';
import { ILaunchpointPaymentsModelIds, ILaunchpointPaymentsModelIdsBase, PAYMENTS_MODEL_POPULATE_ARRAY } from '@launchpoint/payments-types';
import { PopulateOptions } from 'mongoose';
import { ICompany } from '../../companies/interfaces/company.interface';

export enum EUserAccountType {
  ADMIN = 'Admin',
  CLIENT = 'Client',
}

export interface IUserAccountBase extends ICoreUserAccountBase {
  account_id: string;
  user_id: string;
  account_type: EUserAccountType;
}

export interface IUserAccount extends ICoreUserAccount {
  account_id: ICompany;
  user_id: IUser;
  account_type: EUserAccountType;
}

export type IUserJWTAccount = Pick<
  IUserAccount,
  '_id' | 'account_id' | 'account_type' | 'security_roles' | 'selected' | 'invited' | 'status' | 'user_id' | 'name'
>;

export interface IUserJWTToken extends ICoreUserJWTToken {
  accounts: IUserAccount[];
}

export interface IUserJWTDecorator extends ICoreUserJWTDecorator {
  jwt_user: IUserJWTToken;
  account?: IUserJWTAccount;
}

export type IUserJWTFlat = IUserJWTToken & IUserJWTAccount;

export interface IUserBase extends ICoreUserBase, ILaunchpointPaymentsModelIdsBase {
  accounts: IUserAccountBase[];
}

export interface IUser extends ICoreUser, ILaunchpointPaymentsModelIds {
  accounts: IUserAccount[];
}

export const POPULATE_USER: PopulateOptions[] = [
  ...PAYMENTS_MODEL_POPULATE_ARRAY,
  {
    path: 'accounts.user_id',
  },
  {
    path: 'accounts.account_id',
    // this will deep populate the payments details on the company
    populate: PAYMENTS_MODEL_POPULATE_ARRAY,
  },
];
