/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Directive, inject, OnDestroy, Optional, PLATFORM_ID, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { UserAuthActions, UserSelectors } from '@launchpoint/core-client';
import { environment } from '@launchpoint-app/client';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, filter, Observable, skipWhile, Subject, takeUntil } from 'rxjs';
// import Intercom from '@intercom/messenger-js-sdk';
import { IIntercomConfig, IIntercomService, IIntercomSpace, INTERCOM_CONFIG_TOKEN } from './interface';

/***
 * IntercomService
 * @requires _config {IIntercomConfig} as INTERCOM_CONFIG_TOKEN
 *
 * @example in AppModule or AppComponent PROVIDERS
 *
 * ```
   IntercomService, // required
    {
      provide: INTERCOM_CONFIG_TOKEN,
      useFactory: () => {
        // optional for watching Effects
        const actions$ = inject(Actions);

        return {
          app_id: 'some string' // required
          token: 'intercom token', // required,
          action$: actions$, // optional
          debug: false | true, // optional
        };
      },
 * ```
 */

@Directive()
export class IntercomService implements IIntercomService, OnDestroy {
  private key: string;
  private app_id: string;
  private config: {
    app_id: string;
  };
  private debug = false;
  private user$: Observable<any> | undefined;
  private _destroy$?: Subject<unknown>;
  private renderer2: Renderer2;

  private rendererFactory = inject(RendererFactory2);
  private _meta = inject(Meta);
  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);
  private _config: IIntercomConfig = inject(INTERCOM_CONFIG_TOKEN);

  private readonly window: any = this.document?.defaultView;
  public Intercom: any = this.window?.Intercom;

  private actions$: Actions | undefined;

  constructor(
    @Optional() private _Store: Store,
    @Optional() private router: Router
    // @Optional() private actions$: Actions // @rob ?? help
  ) {
    if (this.debug) console.log('IntercomService Registered');
  }

  private log(...args: any[]) {
    if (this.debug) console.log(...args);
  }

  public init() {
    if (!this._config || !Object.keys(this._config).length) {
      console.error('Intercom Config not provided');
      return;
    }

    this.key = this._config.token;
    this.app_id = this._config.app_id;
    this.debug = this._config?.debug || false;
    this.config = {
      app_id: this._config.app_id,
    };

    if (this._config.actions$) {
      this.actions$ = this._config.actions$;
    }

    this.user$ = this._Store && (this._Store.select(UserSelectors.selectUser) as Observable<any>);
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.renderer2 = this.rendererFactory.createRenderer(document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {},
    });

    if (environment?.environmentName === 'prod' || environment?.environmentName === 'local') {
      this.log('.... Booting intercom.....');

      this.boot(this.config);

      this.actions$ &&
        this.actions$.pipe(ofType(UserAuthActions.logoutSuccess)).subscribe({
          next: (data) => {
            this.shutdown();
            this.boot(this.config);
          },
        });
    } else {
      /**
       * Should prevent crawllers from indexing the dev and test sites to the search engines.
       */
      this._meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
      // <meta name="robots" content="noindex, nofollow" />;
    }
  }

  private get destroy$() {
    if (!this._destroy$) {
      // Perf optimization:
      // since this is likely used as base component everywhere
      // only construct a Subject instance if actually used
      this._destroy$ = new Subject();
    }
    return this._destroy$;
  }

  public ngOnDestroy() {
    this.log('onDestroy IntercomService');

    this.shutdown();

    if (this._destroy$) {
      this._destroy$.next(true);
      this._destroy$.complete();
    }
  }

  public boot(intercomData): void {
    this.log('Booting up Intercom');
    if (!isPlatformBrowser(this.platformId)) {
      this.log('!isPlatformBrowser');
      return;
    }
    if (!intercomData.app_id) {
      console.error('Intercom app_id is required');
      return;
    }
    const app_id = intercomData.app_id;

    this.loadIntercom(this.config, (event?: Event) => {
      this.log('Boot Intercom Callback');
      const data = { ...intercomData, app_id };
      return this._callIntercom('boot', data);
    });
  }

  private loadIntercom(config, afterLoadCallback: (ev?: Event) => any): void {
    this.log('loadIntercom');
    if (!isPlatformBrowser(this.platformId)) {
      this.log('!isPlatformBrowser');
      return;
    }

    // get Intercom from window
    const ic = this.window.Intercom;
    this.log('Set window config for Intercom', ic);
    // set class variable
    this.Intercom = ic;

    // Set window config for Intercom
    this.window.intercomSettings = config;
    this.log('this.window.intercomSettings = config', this.window.intercomSettings);

    this.log('Run through Intercom Functions');
    if (typeof ic === 'function') {
      this.log('IC is a function');

      ic('reattach_activator');
      ic('update', config);
      afterLoadCallback();
    } else {
      this.log('IC is not a function, making it one');
      const i: any = function () {
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args: any) {
        i.q.push(args);
      };
      this.Intercom = i;

      this.log('Injecting Intercom Script');
      this.injectIntercomScript(config, afterLoadCallback);
    }
  }

  private injectIntercomScript(conf, afterInjectCallback: (ev: Event) => any): void {
    this.log('Set the window configuration to conf');
    if (!isPlatformBrowser(this.platformId)) {
      this.log('!isPlatformBrowser');
      return;
    }
    // Set the window configuration to conf
    this.log('setting window.intercomSettings = conf');
    this.window.intercomSettings = conf;

    const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${this.app_id}`;

    this.log('Attaching script to the document');
    if ((s as any).attachEvent) {
      (s as any).attachEvent('onload', afterInjectCallback);
    } else {
      s.addEventListener('load', afterInjectCallback, false);
    }

    this.log('Appending script to the head of the document');
    if (this.renderer2 && this.renderer2.appendChild) {
      this.renderer2.appendChild(this.document.head, s);
    }
    try {
      this.update(conf);
    } catch (error) {
      this.log(error);
    }
  }

  private _callIntercom(fn: string, ...args: any[]) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (this.Intercom) {
      try {
        this.Intercom(fn as any, ...args);
        this.subscribeUser();
        return;
      } catch (error) {
        console.error(error);
      }
    }
    return;
  }

  private loadIntercomScript(callback: Function) {
    const script = this.renderer2.createElement('script');
    script.src = 'https://widget.intercom.io/widget/mhpz7btk';
    script.type = 'text/javascript';
    script.async = true;

    script.onload = () => {
      callback();
    };

    this.renderer2.appendChild(this.document.body, script);
  }

  private subscribeUser() {
    this.user$ &&
      this.user$
        .pipe(
          takeUntil(this.destroy$),
          skipWhile((f) => !f),
          delay(500)
        )
        .subscribe({
          next: (user) => {
            this.log(user);
            // https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#section-mapping-of-javascript-attributes-to-intercom-dashboard-and-api

            // created_at: user.created_at, // Signup date as a Unix timestamp
            // this.log({ user: user.profile });
            if (!user) {
              this.log('Intercom User not found');
              return;
            }
            try {
              const updateUser: {
                user_id?: string;
                name?: string;
                email?: string;
                company?: any;
                environment: 'prod' | 'dev' | 'local';
              } = {
                user_id: user._id,
                environment: environment?.environmentName || 'dev',
                name: user.profile?.full_name || user.email.split('@')[0],
                email: user.email,
              };
              if (user?.accounts) {
                const account = user.accounts?.find((f) => f.selected === true);
                if (account && account?.user_id) {
                  // Company ID and company name are the minimum requirements to pass a company into Intercom.
                  // https://wwthis.window.intercom.com/help/en/articles/186-group-your-users-by-company
                  updateUser.company = {
                    company_id: account.account_id,
                    name: account.account?.company_name,
                    // Custom
                    account_type: account.account_type,
                    // products: account.account.products.map((p) => p.product.title),
                    remote_created_at: new Date(account.account.created_at).getTime(),

                    // plan: account.account.products[0].subscriptions[0].items[0]
                  };

                  this.log({ updateUser });
                }
              }
              if (!this.Intercom) {
                console.error('ERROR INTERCOM NOT ON WINDOW?');
              } else {
                this.update(updateUser);
              }
            } catch (error) {
              this.log('Int Err');
              console.error(error);
            }
          },
        });

    this.router &&
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          takeUntil(this.destroy$)
        )
        .subscribe((event) => {
          try {
            if (environment?.environmentName === 'prod') {
              this.update();
            }
          } catch (error) {
            this.log('Int Err');
            this.log(error);
          }
        });
  }

  public update(data?: any): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    return this.Intercom('update', data);
  }

  public show(): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    return this.Intercom('show');
  }
  public hide(): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    return this.Intercom('hide');
  }
  public shutdown(): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    return this.Intercom('shutdown');
  }
  public showNewMessage(content: string): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    return this.Intercom('showNewMessage');
  }
  public showMessages(): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    return this.Intercom('showMessages');
  }
  public getVisitorId(): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    return this.Intercom('getVisitorId');
  }
  public startTour(tourId: number): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    if (!tourId) {
      console.error('tourId is required');
      return;
    }
    return this.Intercom('startTour', tourId);
  }
  public showArticle(articleId: number): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    if (!articleId) {
      console.error('articleId is required');
      return;
    }
    return this.Intercom('showArticle', articleId);
  }
  public showNews(newsItemId: number): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    if (!newsItemId) {
      console.error('newsItemId is required');
      return;
    }
    return this.Intercom('showNews', newsItemId);
  }
  public startSurvey(surveyId: number): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    if (!surveyId) {
      console.error('surveyId is required');
      return;
    }
    return this.Intercom('startSurvey', surveyId);
  }
  public startChecklist(checklistId: number): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    if (!checklistId) {
      console.error('checklistId is required');
      return;
    }
    return this.Intercom('startChecklist', checklistId);
  }
  public showTicket(ticketId: number): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    if (!ticketId) {
      console.error('ticketId is required');
      return;
    }
    return this.Intercom('showTicket', ticketId);
  }
  public showConversation(conversationId: number): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    if (!conversationId) {
      console.error('conversationId is required');
      return;
    }
    return this.Intercom('showConversation', conversationId);
  }
  public showSpace(space: IIntercomSpace): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    if (!space) {
      console.error('space is required');
      return;
    }
    return this.Intercom('showSpace', space);
  }
  public trackEvent(event: string, metadata?: any): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    if (!event) {
      console.error('event is required');
      return;
    }
    return this.Intercom('trackEvent', event, metadata);
  }
  public onHide(callback: () => void): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    return this.Intercom('onHide', callback);
  }
  public onShow(callback: () => void): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    return this.Intercom('onShow', callback);
  }
  public onUnreadCountChange(callback: (unreadCount: number) => void): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    return this.Intercom('OnUnreadCountChange', callback);
  }
  public onUserEmailSupplied(callback: (email: string) => void): void {
    if (!this.Intercom) {
      console.warn('INTERCOM NOT ON WINDOW');
      return;
    }
    return this.Intercom('onUserEmailSupplied', callback);
  }
}
