import {
  ELaunchpointUserRoles,
  ELaunchpointUserViewResources,
  ESecurityCoreUserInvitationsResources,
  ESecurityCoreUserResources,
  ESecurityCoreUserRoleResources,
  ICoreUser,
  ICoreUserInvitation,
  ISecurityConfig,
  ISecurityConfigACLRole,
  ICoreUserJWTFlat,
  IUserView,
  LAUNCHPOINT_USER_INVITATION_SCHEMA_NAME,
  LAUNCHPOINT_USER_SCHEMA_NAME,
  SECURITY_PERMISSIONS_DEFAULT_ROLE,
  LAUNCHPOINT_USER_VIEW_SCHEMA_NAME,
} from '@launchpoint/core-types';

export const DEFAULT_ROLE_USER: ISecurityConfigACLRole<ICoreUserJWTFlat, ICoreUser>[] = [
  {
    effect: 'can',
    permissions: [
      {
        actions: [ESecurityCoreUserResources.USER_ATTRIBUTES_UPDATE, ESecurityCoreUserResources.USER_ATTRIBUTES_DELETE],
        subject: LAUNCHPOINT_USER_SCHEMA_NAME,
      },
    ],
    conditions: [{ _id: '${user_id}' }],
  },
  {
    effect: 'can',
    permissions: [
      {
        actions: [ESecurityCoreUserResources.USER_ACCOUNT_GET_PENDING_INVITES, ESecurityCoreUserResources.USER_ACCOUNT_ACCEPT_INVITATION],
        subject: LAUNCHPOINT_USER_SCHEMA_NAME,
      },
    ],
    conditions: [{ 'accounts.user_id': '${_id}' }],
  },
  {
    effect: 'can',
    permissions: [
      {
        actions: Object.values(ESecurityCoreUserResources).filter(
          (action) => ![ESecurityCoreUserResources.ADMIN_USER_EMAIL_SET, ESecurityCoreUserResources.ADMIN_USER_EMAIL_VERIFY].includes(action)
        ),
        subject: LAUNCHPOINT_USER_SCHEMA_NAME,
      },
    ],
    conditions: [{ _id: '${user_id}' }, { 'accounts.user_id': '${user_id}' }],
  },
  {
    effect: 'can',
    permissions: [
      {
        actions: Object.values(ESecurityCoreUserRoleResources),
        subject: LAUNCHPOINT_USER_SCHEMA_NAME,
      },
    ],
    conditions: [{ _id: '${_id}', security_roles: { $nin: ['super_admin', 'admin'] } }],
  },
];

export const DEFAULT_ROLE_USER_INVITATION: ISecurityConfigACLRole<ICoreUserJWTFlat, ICoreUserInvitation>[] = [
  {
    effect: 'can',
    permissions: [
      {
        actions: Object.values(ESecurityCoreUserInvitationsResources),
        subject: LAUNCHPOINT_USER_INVITATION_SCHEMA_NAME,
      },
    ],
    conditions: [{ sender_user_id: '${_id}' }, { recipient_user_id: '${_id}' }],
  },
];

export const USER_VIEW_CONFIG: ISecurityConfig<ICoreUserJWTFlat, IUserView> = {
  debug: false,
  acl: {
    [SECURITY_PERMISSIONS_DEFAULT_ROLE]: [
      {
        effect: 'can',
        permissions: [{ subject: LAUNCHPOINT_USER_VIEW_SCHEMA_NAME, actions: Object.values(ELaunchpointUserViewResources) }],
        conditions: [{ user_id: '${_id}' }],
      },
    ],
  },
};

export const DEFAULT_ROLE = [...DEFAULT_ROLE_USER, ...DEFAULT_ROLE_USER_INVITATION];

export const USER_ABAC: ISecurityConfig<ICoreUserJWTFlat, ICoreUser> = {
  debug: false,
  acl: {
    [SECURITY_PERMISSIONS_DEFAULT_ROLE]: DEFAULT_ROLE,
    // add any other specific user roles here
    [ELaunchpointUserRoles.USER]: DEFAULT_ROLE,
    [ELaunchpointUserRoles.ADMIN]: [
      {
        effect: 'can',
        permissions: [
          {
            actions: [...Object.values(ESecurityCoreUserResources), ...Object.values(ESecurityCoreUserRoleResources)],
            subject: LAUNCHPOINT_USER_SCHEMA_NAME,
          },
        ],
        conditions: [{ security_roles: { $ne: 'super_admin' } }],
      },
    ],
    [ELaunchpointUserRoles.SUPER_ADMIN]: [
      {
        effect: 'can',
        permissions: [{ subject: LAUNCHPOINT_USER_SCHEMA_NAME, actions: ['manage'] }],
      },
    ],
  },
};
