@if (href) {
  <a [href]="href" target="_blank" class="group">
    <div class="font-courierNew bg-primary flex w-full items-center justify-center gap-4 py-4 text-sm font-semibold md:text-xl">
      <span class="transition-transform duration-500 group-hover:scale-105">{{ text }}</span>
      <i class="fa-regular fa-arrow-right side-to-side"></i>
    </div>
  </a>
} @else if (route) {
  <div
    [routerLink]="[route]"
    class="font-courierNew bg-primary group flex w-full cursor-pointer items-center justify-center gap-4 py-4 text-sm font-semibold md:text-xl"
  >
    <span class="transition-transform duration-500 group-hover:scale-105">{{ text }}</span>
    <i class="fa-regular fa-arrow-right side-to-side"></i>
  </div>
} @else {
  <div class="font-courierNew bg-primary group flex w-full items-center justify-center gap-4 py-4 text-sm font-semibold md:text-xl">
    <span class="transition-transform duration-500 group-hover:scale-105">{{ text }}</span>
    <i class="fa-regular fa-arrow-right side-to-side"></i>
  </div>
}
