<div class="wrapper">
  @for (item of social_links; track $index) {
    @if (item.active) {
      @if (item.href) {
        <a
          [href]="item.href"
          target="_blank"
          class="icon {{ item.tooltip }} 2xl:h-12 2xl:w-12 xl:h-10 xl:w-10 lg:h-8 lg:w-8 text-md md:text-2xl w-6 h-6"
        >
          <span class="tooltip">{{ item.tooltip }}</span>

          <i [class]="item.icon"></i>
        </a>
      }
    }
  }
</div>
