import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'sd-about-turning-point',
  templateUrl: './about-turning-point.component.html',
  styleUrl: './about-turning-point.component.scss',
})
export class AboutTurningPointComponent implements AfterViewInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    const element = this.el.nativeElement.querySelector('.fade-in');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.renderer.addClass(element, 'visible');
          }
        });
      },
      {
        threshold: 0.4,
      }
    );

    observer.observe(element);
  }
}
