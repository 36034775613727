<div
  class="py-10 md:py-20"
  [ngClass]="{
    'bg-background': type === 'dark',
    'bg-light': type === 'light',
    'bg-medium': type === 'medium',
  }"
>
  <div
    class="fade-in mx-auto max-w-[1700px] px-4"
    [ngClass]="{
      'text-white': type === 'dark',
      'text-black': type !== 'dark',
    }"
  >
    <div class="flex flex-col xl:flex-row">
      <div class="mb-10 xl:w-[30%]">
        <h4 class="font-oswald pr-0 text-[30px] font-semibold leading-normal lg:pr-10 lg:text-[60px] lg:font-medium xl:pr-20 2xl:text-[70px]">
          {{ title }} <br *ngIf="subtitle" />
          <span
            *ngIf="subtitle"
            [ngClass]="{
              'text-black/60': type !== 'dark',
              'text-white/60': type === 'dark',
            }"
            >{{ subtitle }}</span
          >
        </h4>
      </div>
      <div class="relative overflow-hidden xl:w-[70%]">
        <div
          #scrollContainer
          class="flex snap-x snap-mandatory gap-10 overflow-x-auto scroll-smooth"
          style="-ms-overflow-style: none; scrollbar-width: none; overflow: -moz-scrollbars-none"
        >
          @for (item of stories; track $index) {
            <div
              class="flex-shrink-medium flex w-[200px] snap-center flex-col justify-between gap-8 border-b-2 pb-4 md:w-[400px]"
              [ngClass]="{
                'border-white': type === 'dark',
                'border-black': type !== 'dark',
              }"
            >
              <div class="group relative h-[265px] w-[200px] cursor-pointer md:h-[500px] md:w-full">
                <img
                  [src]="[item.img]"
                  alt="success story image 1"
                  class="h-full w-full object-cover transition-transform duration-500 ease-out group-hover:translate-y-6"
                />

                <!-- Overlay -->
                <div
                  class="absolute inset-0 flex flex-col justify-end bg-black/0 text-white transition-all duration-500 ease-out group-hover:translate-y-6 group-hover:bg-black/60"
                >
                  <div class="p-2 opacity-0 transition-all duration-500 ease-out group-hover:!opacity-100 md:!p-5">
                    <!-- <p class="mb-2 text-sm font-semibold md:!mb-4 md:text-lg">{{ item.title }}</p> -->
                    <a
                      class="font-courierNew bg-primary inline-block px-4 py-2 text-xs font-bold text-black transition-transform duration-300 ease-in-out hover:scale-110 md:text-base"
                      [href]="item.href ?? '/'"
                      target="_blank"
                      >Read More</a
                    >
                  </div>
                </div>
              </div>

              <div class="text-sm font-medium leading-none md:text-lg">{{ item.title }}</div>
              <span class="font-archivo font-medium uppercase tracking-widest text-[#8B8A8A]">{{ item.date | date: 'dd MMMM YYYY' }}</span>
            </div>
          }
        </div>
        <div class="mt-10 flex justify-end gap-2 md:hidden" *ngIf="showScroll">
          <button (click)="scrollLeft()" class="bg-white/30 px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#010101]">&larr;</button>
          <button (click)="scrollRight()" class="bg-white/30 px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#010101]">&rarr;</button>
        </div>
      </div>
    </div>
  </div>
</div>
