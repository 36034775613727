import { isPlatformBrowser } from '@angular/common';
import { afterNextRender, AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { SOCIAL_LINKS } from '../../../../config/config';

@Component({
  selector: 'sd-tools-tips',
  templateUrl: './tools-tips.component.html',
  styleUrl: './tools-tips.component.scss',
})
export class ToolsTipsComponent implements OnInit, AfterViewInit {
  social_links = SOCIAL_LINKS;

  private isBrowser: boolean;
  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    afterNextRender(() => {
      this.isBrowser = true;
    });
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    return;
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      const script = document.createElement('script');
      script.src = '//www.instagram.com/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }
}
