<div class="bg-background pb-20 pt-32 md:pb-40 lg:pt-60">
  <div class="fade-in mx-auto flex max-w-[1700px] flex-col justify-around lg:flex-row xl:px-40">
    <div class="mb-10 flex flex-col justify-between space-y-10 px-4 text-white md:w-[80%] lg:w-[500px]">
      <div>
        <p class="font-oswald border-b border-white pb-14 text-6xl">ROB STADNICK</p>
        <p class="pt-6 text-sm font-[400] leading-relaxed lg:text-base">Father. Entrapeneur. Car Enthusiest</p>
      </div>
      <div>
        <h1 class="font-oswald mb-3 text-[24px] lg:text-[30px]">HOW IT STARTED</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          Inspired by my grandfather's service and the events of 9/11, I set my sights on joining the Marines. I graduated early, enlisted, and
          immersed myself in tech to build a solid foundation for the future.
        </p>
      </div>
      <div>
        <h1 class="font-oswald mb-3 text-[24px] lg:text-[30px]">WHY I DO WHAT I DO</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          I'm bringing big technology solutions to small businesses because they deserve better.
        </p>
      </div>
      <div>
        <h1 class="font-oswald mb-3 text-[24px] lg:text-[30px]">OPENED LAUNCHPOINT, A VETERAN OWNED BUSINESS</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          Created to bring high-powered tech solutions to businesses of all sizes, with a special focus on empowering small and mid-sized companies
          with tools typically reserved for industry giants.
        </p>
      </div>
    </div>
    <div class="flex items-center justify-center px-4">
      <img src="./assets/img/stadnick.webp" alt="Rob Stadnick" class="rounded-lg object-cover lg:h-[600px] lg:w-[600px]" />
    </div>
  </div>
</div>
