import { CommonModule, isPlatformBrowser } from '@angular/common';
import { afterNextRender, AfterViewInit, Component, ElementRef, Inject, Input, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'sd-success-stories',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './success-stories.component.html',
  styleUrl: './success-stories.component.scss',
})
export class StadnickSuccessStoriesComponent implements AfterViewInit {
  @Input() title = 'SUCCESS STORIES';
  @Input() subtitle;
  @Input() showScroll = false;
  @Input() type: 'light' | 'medium' | 'dark' = 'light';

  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  private isBrowser: boolean;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    afterNextRender(() => {
      this.isBrowser = true;
    });
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  scrollByAmount = 200;

  scrollLeft() {
    const container = this.scrollContainer.nativeElement;
    container.scrollBy({ left: -this.scrollByAmount, behavior: 'smooth' });
  }

  scrollRight() {
    const container = this.scrollContainer.nativeElement;
    container.scrollBy({ left: this.scrollByAmount, behavior: 'smooth' });
  }

  stories: {
    title: string;
    date: Date;
    img: string;
    href?: string;
  }[] = [
    {
      title: 'Transforming a Local Event Business with Event and Marketing Stack',
      date: new Date('2024-02-21'),
      img: 'assets/img/success-stories/computer.png',
    },
    {
      title: 'Revamping an Insurance Firm’s Tech Infrastructure With AI',
      date: new Date('2018-06-30'),
      img: 'assets/img/success-stories/tv.png',
    },
    {
      title: 'Helping 20 Veteran Businesses This Year',
      date: new Date('2024-11-21'),
      img: 'assets/img/success-stories/ropes.png',
    },
  ];

  ngAfterViewInit(): void {
    const element = this.el.nativeElement.querySelector('.fade-in');

    if (this.isBrowser) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.renderer.addClass(element, 'visible');
            }
          });
        },
        {
          threshold: 0.4,
        }
      );

      observer.observe(element);
    }
  }
}
