import {
  DynamicFormResponseComputeService,
  ELaunchpointResponseStatus,
  ILaunchpointDynamicFormResponse,
  ILaunchpointDynamicFormResponseSelectorResult,
} from '@launchpoint-app/types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LaunchpointDynamicFormResponseEntityState } from '../interface/interface';
import {
  LaunchpointDynamicFormResponseEntityAdaptor,
  LaunchpointDynamicFormResponseFeatureKey,
  LaunchpointDynamicFormResponseState,
} from '../reducers/reducer';

const selectAllLaunchpointDynamicFormResponseState = createFeatureSelector<LaunchpointDynamicFormResponseState>(
  LaunchpointDynamicFormResponseFeatureKey
);

/**
 * ROOT STATE
 */
export const getAllLaunchpointDynamicFormResponseState = createSelector(
  selectAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state
);

export const selectLaunchpointDynamicFormResponsePagination = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.pagination
);
export const selectLaunchpointDynamicFormResponseQuerySort = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.querySort
);
export const selectLaunchpointDynamicFormResponseQuery = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.query
);
export const selectLaunchpointDynamicFormResponseLoading = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.loading
);
export const selectLaunchpointDynamicFormResponseLoaded = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.loaded
);
export const selectLaunchpointDynamicFormResponseError = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.error
);
export const selectLaunchpointDynamicFormResponseSuccess = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.success_message
);

export const selectLaunchpointDynamicFormResponsePrimaryForm = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.primaryForm
);

export const selectLaunchpointDynamicFormResponseYears = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.years
);

export const getAllLaunchpointDynamicFormResponseStateEntities = createSelector(
  selectAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.entities
);

/**
 * Select Year 1
 */
export const selectLaunchpointDynamicFormResponseYear1 = createSelector(
  selectAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.year_1
);

/**
 * Select Year 2
 */
export const selectLaunchpointDynamicFormResponseYear2 = createSelector(
  selectAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state.year_2
);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = LaunchpointDynamicFormResponseEntityAdaptor.getSelectors(
  selectAllLaunchpointDynamicFormResponseState
);

// select the array of ids
export const selectLaunchpointDynamicFormResponseIds = selectIds;

// select the dictionary of entities
export const selectLaunchpointDynamicFormResponseEntities = selectEntities;

// select the array of entities
export const selectLaunchpointDynamicFormResponse = selectAll;

// select the total entity count
export const selectLaunchpointDynamicFormResponseTotal = selectTotal;

export const selectLaunchpointDynamicFormResponseId = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => state?.selected_dynamic_form_response_id
);

export const selectSelectedLaunchpointDynamicFormResponseCount = (formId: string) =>
  createSelector(getAllLaunchpointDynamicFormResponseState, (state: LaunchpointDynamicFormResponseState) => {
    const counts = {
      completed: 0,
      partial: 0,
      loaded: false,
      error: state?.error?.error,
    };

    try {
      const data = Object.keys(state.entities)
        .map((key) => ({
          key: key,
          value: state.entities[key].dynamic_form_response,
        }))
        .filter((f) => f.value.form._id === formId);
      counts.completed = data.filter((f) => f.value.response_status === ELaunchpointResponseStatus.COMPLETE).length;
      counts.partial = data.filter((f) => f.value.response_status === ELaunchpointResponseStatus.PARTIAL).length;
    } catch (error) {
      console.log(error);
    }

    if (state.loaded === true && state.loading === false) {
      counts.loaded = true;
    } else {
      counts.loaded = false;
      // console.log('COULD BE FALSE');
    }
    return counts;
  });

export const getProjectionForNextYear = (formId: string) =>
  createSelector(
    selectLaunchpointDynamicFormResponse,
    selectLaunchpointDynamicFormResponsePrimaryForm,
    selectLaunchpointDynamicFormResponseYear1,
    (responses: LaunchpointDynamicFormResponseEntityState[], primaryForm, year1: number): ILaunchpointDynamicFormResponse => {
      if (!responses || responses.length === 0) return null;
      const computeService = new DynamicFormResponseComputeService();
      const allResponsesForFrom = responses.filter((r) => r.dynamic_form_response.form._id === formId).map((f) => f.dynamic_form_response);

      const year1Responses = year1 ? computeService.findResponsesByYear(year1, allResponsesForFrom) : null;
      const latestYear = Math.max(
        ...allResponsesForFrom.map((r) =>
          parseInt(r.responses.find((response) => response.question_id === primaryForm.year_question_id)?.answer_value)
        )
      );
      const predictedResponse = computeService.predictNextYearResponse(allResponsesForFrom, allResponsesForFrom[0].form, latestYear);

      // let feild_data: ILaunchpointDynamicFormComputedField = null;
      // let comparisonResult: {
      //   year_1: number | null;
      //   year_2: number | null;
      //   value_year_1: number | null;
      //   value_year_2: number | null;
      //   difference: number | null;
      //   percentage_change: number | null;
      // } = null;
      return predictedResponse;
      // feild_data = allResponsesForFrom[0]?.form?.computed_fields?.find((cf) => cf._id === computedFieldId);
      // // Perform comparison using the service
      // if (year1Response && year2Response) {
      //   comparisonResult = computeService.compareValuesByYear(computedFieldId, year1, year2, allResponsesForFrom, true);
    }
    // console.log({ comparisonResult });
    // const chart_data = computeService.findComputedValuesById(computedFieldId, allResponsesForFrom);
    // console.log({ computeService: chart_data });
    // return {
    //   computed_field: feild_data,
    //   chart_data,
    //   year_1: null,
    //   year_2: null,
    //   comparison: comparisonResult, // Added comparison result
    //   responses: allResponsesForFrom,
    // };
  );
export const selectComputedFieldDetails = (feildId: string, formId: string) =>
  createSelector(
    selectLaunchpointDynamicFormResponse,
    selectLaunchpointDynamicFormResponseYear1,
    selectLaunchpointDynamicFormResponseYear2,
    (responses: LaunchpointDynamicFormResponseEntityState[], year1: number, year2: number): ILaunchpointDynamicFormResponseSelectorResult => {
      const returnValue: ILaunchpointDynamicFormResponseSelectorResult = {
        computed_field: null,
        question_field: null,
        chart_data: null,
        year_1: null,
        year_2: null,
        comparison: null, // Added comparison result
        responses: null,
      };
      try {
        if (!responses || responses.length === 0) return returnValue;
        const computeService = new DynamicFormResponseComputeService();
        // console.log('responses', responses.length);
        // console.log('year1', year1);
        // console.log('year2', year2);
        const allResponsesForFrom = responses
          .filter((r) => r.dynamic_form_response.form._id === formId)
          .map((f) => f.dynamic_form_response)
          .filter((f) => f.response_status === ELaunchpointResponseStatus.COMPLETE);
        // console.log('filteredResponses', allResponsesForFrom.length);
        returnValue.responses = allResponsesForFrom;
        const year1Responses = year1 ? computeService.findResponsesByYear(year1, allResponsesForFrom) : null;
        // const year1Returned = year1Responses?.responses.find((f) => f?.question_id === '6615e673a3e3bd051156b30f');

        returnValue.year_1 = year1Responses?.responses.find((f) => f?.computed_field_id === feildId || f?.question_id === feildId);
        // console.log({ year1Returned: returnValue.year_1 });
        // console.log('year1Response', returnValue.year_1?.answer_value);
        const year2Responses = year2 ? computeService.findResponsesByYear(year2, allResponsesForFrom) : null;
        // const year2Returned = year2Responses?.responses.find((f) => f?.question_id === '6615e673a3e3bd051156b30f');

        returnValue.year_2 = year2Responses?.responses.find((f) => f?.computed_field_id === feildId || f?.question_id === feildId);
        // console.log({ year2Returne: returnValue.year_2 });
        // console.log('year2Response', returnValue.year_2?.answer_value);

        const comparisonResult: {
          year_1: number | null;
          year_2: number | null;
          value_year_1: number | null;
          value_year_2: number | null;
          difference: number | null;
          percentage_change: number | null;
        } = {
          year_1: year1,
          year_2: year2,
          value_year_1: null,
          value_year_2: null,
          difference: null,
          percentage_change: null,
        };

        returnValue.computed_field = allResponsesForFrom[0]?.form?.computed_fields?.find((cf) => cf._id === feildId);
        // console.log(returnValue?.computed_field?.field_name);
        returnValue.question_field = allResponsesForFrom[0]?.form?.groups.flatMap((q) => q.questions)?.find((cf) => cf._id === feildId);
        // console.log(returnValue?.question_field?.question_text);

        // Perform comparison using the service
        if (returnValue?.year_1 || returnValue?.year_2) {
          const { value_year_1, value_year_2, difference, percentage_change } = computeService.compareResponses(
            feildId,
            year1Responses,
            year2Responses
          );
          comparisonResult.value_year_1 = value_year_1;
          comparisonResult.value_year_2 = value_year_2;
          comparisonResult.difference = difference;
          comparisonResult.percentage_change = percentage_change;
        }
        // console.log({ comparisonResult });
        returnValue.chart_data = computeService.findComputedValuesById(feildId, allResponsesForFrom);
        // console.log({ chart_data: returnValue.chart_data });
        returnValue.comparison = comparisonResult;

        return returnValue;
      } catch (error) {
        console.log(error);
        return returnValue;
      }
    }
  );

/**
 * Entire entity, includes loading/loaded state to be used on tables and other data.
 */
export const selectLaunchpointDynamicFormResponseEntity = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  selectLaunchpointDynamicFormResponseId,
  (state: LaunchpointDynamicFormResponseState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]
);

/**
 * Selected entity object, no entity data
 */
export const selectSelectedLaunchpointDynamicFormResponse = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  selectLaunchpointDynamicFormResponseId,
  (state: LaunchpointDynamicFormResponseState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]?.dynamic_form_response
);
/**
 * Selected entity loading state
 */
export const selectSelectedLaunchpointDynamicFormResponseLoading = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  selectLaunchpointDynamicFormResponseId,
  (state: LaunchpointDynamicFormResponseState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]?.loading
);
/**
 * Selected entity loaded state
 * This is useful if you want to insure that while loading might not be true the data doesn't exist and an attempt was made to get it
 */
export const selectSelectedLaunchpointDynamicFormResponseLoaded = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  selectLaunchpointDynamicFormResponseId,
  (state: LaunchpointDynamicFormResponseState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]?.loaded
);
/**
 * Selected entity error message
 */
export const selectSelectedLaunchpointDynamicFormResponseError = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  selectLaunchpointDynamicFormResponseId,
  (state: LaunchpointDynamicFormResponseState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]?.error
);
/**
 * Selected entity success message
 */
export const selectSelectedLaunchpointDynamicFormResponseSuccessMessage = createSelector(
  getAllLaunchpointDynamicFormResponseState,
  selectLaunchpointDynamicFormResponseId,
  (state: LaunchpointDynamicFormResponseState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]?.success_message
);
/**
 * Selected entity count for what queries are applied.
 * @default 0
 * Should be no queries at init if possible.
 */
export const selectSelectedLaunchpointDynamicFormResponseQueryCount = createSelector(
  selectAllLaunchpointDynamicFormResponseState,
  (state: LaunchpointDynamicFormResponseState) => {
    let count = 0;
    try {
      // TODO: Add other query objects here
      // count = count + state.query?.ARRAY.length ?? 0;

      // query object string, if not empty | '', then count 1
      if (state.query?.search && state.query?.search.length > 0) {
        count++;
      }
    } catch (error) {
      console.log(error);
    }
    return count;
  }
);
