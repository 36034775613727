import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@launchpoint-app/client';

if (environment.production) {
  console.log(`production environment is ${environment}`);
  console.log(JSON.stringify(environment, null, 2));
  enableProdMode();
} else {
  console.log(`environment is ${environment}`);
  console.log(JSON.stringify(environment, null, 2));
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    ngZoneEventCoalescing: true,
  })
  .catch((err) => console.error(err));
