<div class="bg-[#E9E9E9] py-10 md:py-40">
  <div class="fade-in mx-auto flex max-w-[1700px] flex-col justify-between gap-6 md:gap-16 lg:flex-row xl:px-40">
    <div class="flex items-center justify-center px-4 lg:w-[50%]">
      <img src="./assets/img/story.webp" alt="Launchpoint Logo" class="object-cover lg:h-[406px] lg:w-[625px]" />
    </div>
    <div class="flex flex-col justify-between space-y-10 px-4 md:w-[80%] lg:w-[50%]">
      <div class="space-y-10">
        <h1 class="font-oswald mb-3 text-[24px] lg:text-[30px]">A TURNING POINT IN MY LIFE</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          This photo represents one of the lowest points in my life. At the time, my wife, our daughter and I lived in a run-down apartment complex
          that was raided by SWAT multiple times. I couldn't afford a car, so I walked to the gym every day to coach, staying late into the night to
          study software and AI. At 11 p.m., I'd walk home, often stepping over needles, exhausted but determined.
        </p>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          When I posted this to Instagram, I captioned it with #thankful because, deep down, I knew this moment wouldn't define me forever. I wanted
          to hold onto the memory—not as something to regret, but as a reminder of where I started and the strength it took to keep going.
        </p>
      </div>
    </div>
  </div>
</div>
