import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICircleEventListResponse, ICircleEventParamsList } from '@launchpoint-app/types';
import { configureURL } from '@launchpoint/core-types';
import { Observable } from 'rxjs';

@Injectable()
export class CircleEventHTTPService {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';
  BASE_URL = 'circle-events';

  constructor(private http: HttpClient) {
    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      version: 1,
      route: this.BASE_URL,
    });
  }

  listEventsPublic(params?: ICircleEventParamsList): Observable<ICircleEventListResponse | null> {
    return this.http.post<ICircleEventListResponse>(this.API_URL + '/list-public', params);
  }

  // listEventAttendees(params: ICircleEventAttendeeParamsList): Observable<ICircleEventAttendeeListResponse | null> {
  //   return this.getSelectedAccountIdHeaders.pipe(
  //     switchMap((headers) => {
  //       return this.http.post<ICircleEventAttendeeListResponse>(this.API_URL + '/list-attendees', params, { headers });
  //     })
  //   );
  // }

  // listRecurringEvents(params: ICircleEventRecurringEventParamsList): Observable<ICircleEventRecurringEventListResponse | null> {
  //   return this.getSelectedAccountIdHeaders.pipe(
  //     switchMap((headers) => {
  //       return this.http.post<ICircleEventRecurringEventListResponse>(this.API_URL + '/list-recurring', params, { headers });
  //     })
  //   );
  // }

  // createEventAttendee(params: ICircleEventAttendeeParamsCreate): Observable<ICircleEventAttendee | null> {
  //   return this.getSelectedAccountIdHeaders.pipe(
  //     switchMap((headers) => {
  //       return this.http.post<ICircleEventAttendee>(this.API_URL + '/attendee', params, { headers });
  //     })
  //   );
  // }

  // deleteEventAttendee(event_id: string): Observable<ICircleEventAttendee | null> {
  //   return this.getSelectedAccountIdHeaders.pipe(
  //     switchMap((headers) => {
  //       return this.http.delete<ICircleEventAttendee>(this.API_URL + '/attendee/' + event_id, { headers });
  //     })
  //   );
  // }

  // updateRecurringEventRSVP(params: ICircleEventRecurringEventParamsUpdateRSVP): Observable<string | null> {
  //   return this.getSelectedAccountIdHeaders.pipe(
  //     switchMap((headers) => {
  //       return this.http.put<string>(this.API_URL + '/recurring-rsvp', params, { headers });
  //     })
  //   );
  // }
}
