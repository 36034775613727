import { ELPDefaultActions, ILaunchpointActions } from '@launchpoint/core-types';
import { ILaunchpointDynamicFormResponse } from './response.interface';

export enum ELaunchpointDynamicFormResponse { // These equal the controllers
  RESPONSE = 'response',
  ACCOUNTS = 'response-accounts',
  USERS = 'response-users',
  RESPONSES = 'response-responses',
  AI_ASSISTANT = 'response-chat',
  ANALYTICS = 'analytics',
}

export enum ELaunchpointDynamicFormResponseActions { // Also sometimes called resources
  // Base Service Actions
  BASE_SEARCH = ELaunchpointDynamicFormResponse.RESPONSE + '/' + ELPDefaultActions.SEARCH,
  BASE_GET_BY_ID = ELaunchpointDynamicFormResponse.RESPONSE + '/' + ELPDefaultActions.GET_BY_ID,
  BASE_CREATE = ELaunchpointDynamicFormResponse.RESPONSE + '/' + ELPDefaultActions.CREATE,
  BASE_UPDATE = ELaunchpointDynamicFormResponse.RESPONSE + '/' + ELPDefaultActions.UPDATE,
  BASE_DELETE = ELaunchpointDynamicFormResponse.RESPONSE + '/' + ELPDefaultActions.DELETE,
  BASE_ARCHIVE = ELaunchpointDynamicFormResponse.RESPONSE + '/' + ELPDefaultActions.ARCHIVE,

  ACCOUNTS_ADD = ELaunchpointDynamicFormResponse.ACCOUNTS + '/' + ELPDefaultActions.CREATE,
  ACCOUNTS_REMOVE = ELaunchpointDynamicFormResponse.ACCOUNTS + '/' + ELPDefaultActions.DELETE,

  USERS_ADD = ELaunchpointDynamicFormResponse.USERS + '/' + ELPDefaultActions.CREATE,
  USERS_REMOVE = ELaunchpointDynamicFormResponse.USERS + '/' + ELPDefaultActions.DELETE,

  RESPONSES_ADD = ELaunchpointDynamicFormResponse.RESPONSES + '/' + ELPDefaultActions.CREATE,
  RESPONSES_REORDER = ELaunchpointDynamicFormResponse.RESPONSES + '/' + ELPDefaultActions.REORDER,
  RESPONSES_UPDATE = ELaunchpointDynamicFormResponse.RESPONSES + '/' + ELPDefaultActions.UPDATE,
  RESPONSES_REMOVE = ELaunchpointDynamicFormResponse.RESPONSES + '/' + ELPDefaultActions.DELETE,

  AI_ASSISTANT = ELaunchpointDynamicFormResponse.AI_ASSISTANT + '/' + ELPDefaultActions.CREATE,

  ANALYTICS = ELaunchpointDynamicFormResponse.RESPONSE + '/' + ELaunchpointDynamicFormResponse.ANALYTICS,
}

export type ILaunchpointDynamicFormResponseActionsJSON = ILaunchpointActions<ELaunchpointDynamicFormResponseActions, ILaunchpointDynamicFormResponse>;

export const LaunchpointDynamicFormResponseActionsJSON: ILaunchpointDynamicFormResponseActionsJSON[] = [
  {
    action: ELaunchpointDynamicFormResponseActions.BASE_UPDATE,
    title: 'Open',
    conditions: {}, // TODO:  Add conditions for actions
    active: false,
  },
];
