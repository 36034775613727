import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import {
  ILaunchpointDynamicFormComputedFieldController,
  ELaunchpointDynamicForm,
  ILaunchpointDynamicFormComputedFieldParamsCreate,
  ILaunchpointDynamicForm,
  ILaunchpointDynamicFormComputedFieldParamsUpdate,
  ILaunchpointDynamicFormComputedFieldParamsReorder,
  ILaunchpointDynamicFormComputedFieldParamsDelete,
} from '@launchpoint-app/types';
import { LaunchpointSecurityAccountIdHeaderServiceV2, APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '@launchpoint/core-client';
import { configureURL, ELPDefaultActions } from '@launchpoint/core-types';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class LaunchpointDynamicFormComputedFieldService
  extends LaunchpointSecurityAccountIdHeaderServiceV2
  implements ILaunchpointDynamicFormComputedFieldController
{
  http = inject(HttpClient);
  API_URL = '/api/backend/';

  constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _CoreAuthConfig: ICoreAuthConfig) {
    super();

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _CoreAuthConfig.auth?.base_url,
      version: 1,
      route: ELaunchpointDynamicForm.COMPUTED_FIELDS,
    });
  }

  add(params: ILaunchpointDynamicFormComputedFieldParamsCreate): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointDynamicForm>(this.API_URL, params, { headers });
      })
    );
  }
  update(params: ILaunchpointDynamicFormComputedFieldParamsUpdate): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicForm>(this.API_URL, params, { headers });
      })
    );
  }
  reorder(params: ILaunchpointDynamicFormComputedFieldParamsReorder): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicForm>(this.API_URL + '/' + ELPDefaultActions.REORDER, params, { headers });
      })
    );
  }
  remove(params: ILaunchpointDynamicFormComputedFieldParamsDelete): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicForm>(this.API_URL + '/' + ELPDefaultActions.DELETE, params, { headers });
      })
    );
  }

  // public add(params: ILaunchpointDynamicFormGroupParamsCreate): Observable<ILaunchpointDynamicForm> {
  // return this.getSelectedAccountIdHeaders.pipe(
  //   switchMap((headers) => {
  //     return this.http.post<ILaunchpointDynamicForm>(this.API_URL, params, { headers });
  //   })
  // );
  // }
}
