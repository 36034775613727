import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import {
  ELaunchpointDynamicFormResponse,
  ILaunchpointDynamicFormResponse,
  ILaunchpointDynamicFormResponseController,
  ILaunchpointDynamicFormResponseParamsArchive,
  ILaunchpointDynamicFormResponseParamsCreate,
  ILaunchpointDynamicFormResponseParamsDelete,
  ILaunchpointDynamicFormResponseParamsUpdate,
  ILaunchpointDynamicFormResponseSearchPayload,
  ILaunchpointDynamicFormResponseSearchResults,
} from '@launchpoint-app/types';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig, LaunchpointSecurityAccountIdHeaderServiceV2 } from '@launchpoint/core-client';
import { configureURL, ELPDefaultActions } from '@launchpoint/core-types';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class LaunchpointDynamicFormResponseV1Service
  extends LaunchpointSecurityAccountIdHeaderServiceV2
  implements ILaunchpointDynamicFormResponseController
{
  http = inject(HttpClient);
  API_URL = '/api/backend/';

  constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _CoreAuthConfig: ICoreAuthConfig) {
    super();

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _CoreAuthConfig.auth?.base_url,
      version: 1,
      route: ELaunchpointDynamicFormResponse.RESPONSE,
    });
  }
  public search(params: ILaunchpointDynamicFormResponseSearchPayload): Observable<ILaunchpointDynamicFormResponseSearchResults> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointDynamicFormResponseSearchResults>(this.API_URL + '/' + ELPDefaultActions.SEARCH, params, { headers });
      })
    );
  }

  public getById(id: string): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ILaunchpointDynamicFormResponse>(this.API_URL + '/' + id, { headers });
      })
    );
  }

  public create(params: ILaunchpointDynamicFormResponseParamsCreate): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointDynamicFormResponse>(this.API_URL, params, { headers });
      })
    );
  }

  public update(params: ILaunchpointDynamicFormResponseParamsUpdate): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicFormResponse>(this.API_URL, params, { headers });
      })
    );
  }

  public archive(params: ILaunchpointDynamicFormResponseParamsArchive): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicFormResponse>(this.API_URL + '/' + ELPDefaultActions.ARCHIVE, params, { headers });
      })
    );
  }

  public delete(params: ILaunchpointDynamicFormResponseParamsDelete): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicFormResponse>(this.API_URL + '/' + ELPDefaultActions.DELETE, params, { headers });
      })
    );
  }
}
