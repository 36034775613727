import { Directive, inject } from '@angular/core';
import {
  ELaunchpointFormsInputType,
  ELaunchpointFormTrendIndicator,
  ELaunchpointResponseStatus,
  ILaunchpointDynamicFormResponse,
} from '@launchpoint-app/types';
import { LaunchpointCoreClientBaseComponent, UserSelectors } from '@launchpoint/core-client';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import {
  deleteLaunchpointDynamicFormResponse,
  updateAllLaunchpointDynamicFormResponsePagination,
  updateAllLaunchpointDynamicFormResponseQuery,
} from './actions/actions';
import * as LaunchpointDynamicFormResponseSelectors from './selectors/selectors';

@Directive()
export abstract class LaunchpointDynamicFormResponseFacadeComponent extends LaunchpointCoreClientBaseComponent {
  _Store = inject(Store);
  LaunchpointFormsInputType = ELaunchpointFormsInputType;
  LaunchpointFormsInputTypeValues = Object.values(ELaunchpointFormsInputType);

  LaunchpointResponseStatus = ELaunchpointResponseStatus;
  LaunchpointResponseStatusValues = Object.values(ELaunchpointResponseStatus);

  LaunchpointFormTrendIndicator = ELaunchpointFormTrendIndicator;
  LaunchpointFormTrendIndicatorValues = Object.values(ELaunchpointFormTrendIndicator);
  // All LaunchpointDynamicFormResponse
  primaryForm$ = this._Store.selectSignal(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponsePrimaryForm);
  allYearsSignal = this._Store.selectSignal(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseYears);
  selectedYear1Signal = this._Store.selectSignal(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseYear1);
  selectedYear2Signal = this._Store.selectSignal(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseYear2);

  dynamicFormResponses$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponse);
  allYears$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseYears);
  pagination$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponsePagination); //
  success$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseSuccess);
  loading$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseLoading);
  loaded$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseLoaded);
  error$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseError);
  query$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseQuery);
  // Single LaunchpointDynamicFormResponse
  dynamicFormResponsesEnitity$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseEntity);
  dynamicFormResponse$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectSelectedLaunchpointDynamicFormResponse);
  dynamicFormResponseId$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseId);
  dynamicFormResponseLoading$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseLoading);
  dynamicFormResponseLoaded$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseLoaded);
  // Reports
  dynamicFormResponseError$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseError);
  dynamicFormResponseSuccess$ = this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseSuccess);
  // USER

  userProfile$ = this._Store.select(UserSelectors.selectSelectedUserProfile);
  userAccount$ = this._Store.select(UserSelectors.selectSelectedUserAccount);

  removeFormResponse(response_id: string) {
    this._Store.dispatch(deleteLaunchpointDynamicFormResponse({ data: { _id: response_id } }));
  }

  async updateQuery(search: string) {
    this._Store.dispatch(updateAllLaunchpointDynamicFormResponseQuery({ query: { search } }));
  }

  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(
      updateAllLaunchpointDynamicFormResponsePagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } })
    );
  }

  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllLaunchpointDynamicFormResponsePagination({ pagination: { ...pagination, limit } }));
  }

  getProgress(response: ILaunchpointDynamicFormResponse): number {
    if (!response) {
      return 0;
    }
    const total_questions = response.form.groups.flatMap((f) => f.questions).length;
    // console.log({ total_questions });
    // const total_computed = response.form.computed_fields.length;
    const resposnes = response.responses.filter((res) => response.form.groups.flatMap((f) => f.questions).some((s) => s?._id === res?.question_id));
    // console.log({ response_responses_length: resposnes.length });
    // console.log({ response_responses_length_filter: resposnes.filter((f) => f?.answer_value !== null || f.answer_value !== '').length });
    // console.log({ total_computed });

    // const total = total_computed + total_questions;
    // console.log({ total });
    // console.log({ respones: resposnes.length });
    const percentage = resposnes.length / total_questions;
    // console.log({ percentage });
    return Math.round(percentage * 100);
  }

  public formatCurrency(number: number) {
    if (isNaN(number)) return null;

    const absNumber = Math.abs(number);
    let formattedNumber;

    if (absNumber >= 1e12) {
      formattedNumber = (number / 1e12).toFixed(1) + 'T';
    } else if (absNumber >= 1e9) {
      formattedNumber = (number / 1e9).toFixed(1) + 'B';
    } else if (absNumber >= 1e6) {
      formattedNumber = (number / 1e6).toFixed(1) + 'M';
    } else if (absNumber >= 1e3) {
      formattedNumber = (number / 1e3).toFixed(1) + 'K';
    } else {
      formattedNumber = number.toFixed(2);
    }

    // Remove trailing .0 or .00 if present with T, B, M, K
    formattedNumber = formattedNumber.replace(/\.0(?=[TBMK])/g, '');

    // Remove trailing .00 if present
    if (formattedNumber.endsWith('.00')) {
      formattedNumber = formattedNumber.substring(0, formattedNumber.length - 3);
    }

    return '$' + formattedNumber;
  }
}
