import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaunchpointSEORouterService, ManyChatSubscribeService } from '@launchpoint-app/client';
import {
  LaunchpointBlogCommentRepliesHTTPServicePublic,
  LaunchpointBlogCommentsHTTPServicePublic,
  LaunchpointBlogPublicHTTPService,
} from '@launchpoint/core-client';
import { StadnickButtonComponent } from '../../components/button/button.component';
import { StadnickNewsletterComponent } from '../../components/newsletter/newsletter.component';
import { BlogPageComponent } from './blog.component';
import { BlogRoutingModule } from './blog.routing';
import { BLOG_COMPONENTS } from './components';
import { LaunchpointBlogService } from './data/blog.service';
import { BLOG_PAGE_COMPONENTS } from './pages';

const STANDALONE_COMPONENTS = [StadnickButtonComponent, StadnickNewsletterComponent];

@NgModule({
  imports: [CommonModule, BlogRoutingModule, NgOptimizedImage, FormsModule, ReactiveFormsModule, ...STANDALONE_COMPONENTS],
  declarations: [BlogPageComponent, ...BLOG_COMPONENTS, ...BLOG_PAGE_COMPONENTS],
  providers: [
    LaunchpointBlogService,
    LaunchpointBlogPublicHTTPService,
    ManyChatSubscribeService,
    LaunchpointBlogCommentsHTTPServicePublic,
    LaunchpointBlogCommentRepliesHTTPServicePublic,
    LaunchpointSEORouterService,
  ],
})
export class BlogPageModule {}
