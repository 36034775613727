<div class="bg-background pt-24 md:pt-14">
  <div class="fadeInDown relative mx-auto flex max-w-[1700px] px-4">
    <div class="z-20">
      <h1 class="font-oswald 3xl:text-[300px] text-[22vw] font-medium text-white md:text-[18vw]">STADNICK</h1>
      <h2 class="font-courierNew fadeIn text-secondary -mt-10 ml-10 hidden text-[1.5vw] font-semibold md:block 2xl:text-[28px]">
        Out Study. Out Work. Outlast - For Leaders Ready to Win.
      </h2>
    </div>
    <div class="3xl:top-10 absolute right-6 top-16 z-10 md:right-0 md:top-14">
      <div class="3xl:top-20 absolute right-12 z-20 hidden space-x-4 md:top-10 lg:block 2xl:top-12">
        <sd-social-icons></sd-social-icons>
      </div>
      <h1 class="font-oswald 3xl:text-[400px] text-[22vw] font-medium text-[#D2B48CC2] md:text-[22vw]">ROB</h1>
    </div>
  </div>
  <div class="fadeIn px-4">
    <h2 class="font-courierNew text-secondary mt-4 block font-semibold md:hidden">
      Out Study. Out Work. <br />
      Outlast - For Leaders Ready to Win.
    </h2>
  </div>
  <div class="fadeInUp 3xl:mt-10 relative z-30 md:mt-16 xl:mt-10 2xl:mt-4">
    <img src="./assets/img/hero-image.webp" class="relative z-30 h-[200px] w-full object-cover md:h-auto" />
    <div class="bg-medium flex flex-col justify-center md:flex-row">
      <div class="group px-14 py-4 md:w-[500px] md:px-4 md:py-10 lg:px-10">
        <a href="" class="transition-all duration-500 ease-in-out hover:scale-105">
          <p class="font-courierNew text-[13px] font-semibold uppercase tracking-widest text-[#D99342] lg:text-[16px] xl:text-[20px]">About</p>
          <h3 class="font-oswald text-[25px] font-medium uppercase leading-normal xl:text-[2vw] 2xl:text-[42px]">
            My Story. My Drive. <br />
            Your Advantage.
          </h3>
          <div class="relative mt-2 inline-flex items-center">
            <span
              class="absolute left-0 w-[150px] text-sm uppercase opacity-0 transition-opacity duration-[200ms] ease-out group-hover:opacity-100 group-hover:duration-[1000ms] lg:text-[18px]"
            >
              Learn More
            </span>
            <i
              class="fa-solid fa-arrow-right relative ml-[10px] w-[100px] bg-[#E9E9E9] text-2xl transition-transform duration-500 group-hover:translate-x-[90px] md:group-hover:translate-x-[110px]"
            ></i>
          </div>
        </a>
      </div>

      <div class="group border-t-[1px] border-black px-14 py-4 md:w-[500px] md:border-l-[1px] md:border-t-0 md:px-4 md:py-10 lg:px-10">
        <a href="" class="transition-all duration-500 ease-in-out hover:scale-105">
          <p class="font-courierNew text-[13px] font-semibold uppercase tracking-widest text-[#D99342] lg:text-[16px] xl:text-[20px]">Services</p>
          <h3 class="font-oswald text-[25px] font-medium uppercase leading-normal xl:text-[2vw] 2xl:text-[42px]">
            Strategy That Powers <br />
            the Bold.
          </h3>
          <div class="relative mt-2 inline-flex items-center">
            <span
              class="absolute left-0 w-[150px] text-sm uppercase opacity-0 transition-opacity duration-[200ms] ease-out group-hover:opacity-100 group-hover:duration-[1000ms] lg:text-[18px]"
            >
              Learn More
            </span>
            <i
              class="fa-solid fa-arrow-right relative ml-[10px] w-[100px] bg-[#E9E9E9] text-2xl transition-transform duration-500 group-hover:translate-x-[90px] md:group-hover:translate-x-[110px]"
            ></i>
          </div>
        </a>
      </div>
      <div class="group border-t-[1px] border-black px-14 py-4 md:w-[500px] md:border-l-[1px] md:border-t-0 md:px-4 md:py-10 lg:px-10">
        <a href="" class="transition-all duration-500 ease-in-out hover:scale-105">
          <p class="font-courierNew text-[13px] font-semibold uppercase tracking-widest text-[#D99342] lg:text-[16px] xl:text-[20px]">RESOURCES</p>
          <h3 class="font-oswald text-[25px] font-medium uppercase leading-normal xl:text-[2vw] 2xl:text-[42px]">
            FREE SH*T: WORKSHOPS <br />
            COURSES, EVENTS.
          </h3>
          <div class="relative mt-2 inline-flex items-center">
            <span
              class="absolute left-0 w-[150px] text-sm uppercase opacity-0 transition-opacity duration-[200ms] ease-out group-hover:opacity-100 group-hover:duration-[1000ms] lg:text-[18px]"
            >
              Learn More
            </span>
            <i
              class="fa-solid fa-arrow-right relative ml-[10px] w-[100px] bg-[#E9E9E9] text-2xl transition-transform duration-500 group-hover:translate-x-[90px] md:group-hover:translate-x-[110px]"
            ></i>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
