<div class="bg-background py-14 text-white lg:py-40">
  <div class="fade-in mx-auto flex max-w-[1700px] flex-col justify-around lg:flex-row xl:px-40">
    <div class="font-oswald mb-10 px-4 text-4xl font-[600] uppercase lg:space-y-16 lg:px-0 lg:pt-20 lg:text-8xl">
      <p>For Business</p>
      <p>That Mean</p>
      <p>Business</p>
    </div>

    <div class="flex flex-col justify-between space-y-10 px-4 md:w-[80%] lg:w-[500px]">
      <div>
        <h1 class="font-oswald mb-3 text-[24px] lg:text-[30px]">TECHNICAL FOUNDING</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          From self-taught coder to industry innovator, I took on giants and built software that changed the game. My
          journey started in the Marines
          and led to developing tech that set new standards in insurance—now, I bring that expertise to businesses like
          yours.
        </p>
      </div>
      <div>
        <h1 class="font-oswald mb-3 text-[24px] lg:text-[30px]">DIGITAL TRANSFORMATIONS</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          Outdated systems are a thing of the past. I bring streamlined, scalable tech solutions from billion dollar
          businesses to yours, leveling the playing field with
          infrastructure designed for growth and efficiency.
        </p>
      </div>
      <div>
        <h1 class="font-oswald mb-3 pr-14 text-[24px] lg:pr-0 lg:text-[30px]">BUSINESS AUDIT</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          Your business should be running at its peak—anything less is holding you back. I perform in-depth audits to
          expose gaps and missed
          opportunities, giving you a roadmap to outpace the competition.
        </p>
      </div>
      <div>
        <h1 class="font-oswald mb-3 pr-14 text-[24px] lg:pr-0 lg:text-[30px]">BUSINESS MENTOR</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          Success isn't accidental—it's engineered. I work with businesses ready to go beyond the basics, offering
          mentorship
          that cuts through
          complexity and focuses on clear, strategic moves.
        </p>
      </div>
    </div>
  </div>
</div>