import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CircleEventHTTPService } from '@launchpoint-app/client';
import { RESOURCES_COMPONENTS } from './components';
import { ResourcesComponent } from './resources.component';
import { ResourcesRoutingModule } from './resources.routing';
import { StadnickButtonComponent } from '../../components/button/button.component';
import { StadnickNewsletterComponent } from '../../components/newsletter/newsletter.component';
import { LaunchpointResourcesEventsService } from '../../services/resources.service';
import { StadnickSuccessStoriesComponent } from '../../components/success-stories/success-stories.component';

@NgModule({
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ResourcesRoutingModule,
    StadnickButtonComponent,
    StadnickNewsletterComponent,
    StadnickSuccessStoriesComponent,
  ],
  declarations: [ResourcesComponent, ...RESOURCES_COMPONENTS],
  providers: [LaunchpointResourcesEventsService, CircleEventHTTPService],
})
export class ResourcesPageModule {}
