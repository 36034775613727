import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CircleEventHTTPService } from '@launchpoint-app/client';
import { SocialIconsComponent } from '../../components/common/social-icons/social-icons.component';
import { LaunchpointResourcesEventsService } from '../../services/resources.service';
import { AboutComponent } from './about.component';
import { ABOUT_COMPONENTS } from './components';

@NgModule({
  imports: [CommonModule, NgOptimizedImage, RouterModule, SocialIconsComponent],
  declarations: [...ABOUT_COMPONENTS, AboutComponent],
  providers: [CircleEventHTTPService, LaunchpointResourcesEventsService],
})
export class AboutPageModule {}
