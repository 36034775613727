import { Component } from '@angular/core';
import { SOCIAL_LINKS } from '../../../../config/config';

@Component({
  selector: 'sd-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent {
  social_links = SOCIAL_LINKS;
}
