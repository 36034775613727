<div class="bg-[#E1E2DF] py-10 md:py-40">
  <div class="fade-in mx-auto flex max-w-[1700px] flex-col justify-between md:gap-16 lg:flex-row xl:px-40">
    <div class="flex items-center justify-center px-4 lg:w-[50%]">
      <img src="./assets/img/lp-logo-about.webp" alt="Launchpoint Logo" class="h-auto w-[200px] object-contain lg:h-[266px] lg:w-[477px]" />
    </div>
    <div class="flex flex-col justify-between space-y-10 px-4 md:w-[80%] lg:w-[50%]">
      <div class="space-y-10">
        <h1 class="font-oswald mb-3 hidden text-[24px] md:block lg:text-[30px]">LAUNCHPOINT</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          After more than a decade in software and AI, I left my role as an engineering VP to bring enterprise-level tools to businesses of all sizes.
          I saw a way to make cutting-edge technology—once exclusive to billion-dollar companies—accessible to everyone. With that Launchpoint was
          born.
        </p>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          Today, Launchpoint serves hundreds of clients through our S.C.A.L.E framework, designed to make powerful technology approachable and
          impactful. We believe technology should work for you: streamlining operations, enriching customer experiences, and unlocking boundless
          opportunities for growth.
        </p>
      </div>
    </div>
  </div>
</div>
