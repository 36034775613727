import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { configureURL } from '@launchpoint/core-types';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ManyChatSubscribeService {
  http = inject(HttpClient);
  url = 'api/backend/' + 'v1/';

  constructor() {
    this.url = configureURL({
      baseUrl: 'api/backend/',
      configUrl: environment?.apiUrl,
      version: 1,
      route: 'manychat-subscriber',
    });
  }

  public createSubscriber(params: { email: string; has_opt_in_email: boolean; tags?: string[] }): Observable<{ status: string; data: any }> {
    return this.http.post<{ status: string; data: any }>(this.url + '/public', params);
  }
}
