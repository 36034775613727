import { Observable } from 'rxjs';
import { ILaunchpointDynamicFormResponseStatusAnalytics } from '../response.interface';
import { IDateRange, ICoreCreatedAtAnalytics } from '@launchpoint/core-types';

/**
 * @route `response-analytics`
 */
export interface ILaunchpointDynamicFormResponseAnalyticsController {
  created(params: IDateRange): Promise<ICoreCreatedAtAnalytics> | Observable<ICoreCreatedAtAnalytics>;

  status(): Promise<ILaunchpointDynamicFormResponseStatusAnalytics[]> | Observable<ILaunchpointDynamicFormResponseStatusAnalytics[]>;
}
