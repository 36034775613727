import { RequireOnlyOne } from '@launchpoint/core-types';
import { EStadnickDevPages } from './pages';

export type IMenu = {
  order: number;
  active: boolean;
  icon?: string;
  children?: Omit<IMenu, 'children'>[];
} & RequireOnlyOne<{
  route: string;
  href: string;
}> &
  RequireOnlyOne<{
    title: string;
    image: string;
  }>;

export const HEADER_MENU: IMenu[] = [
  {
    order: 0,
    title: 'Home',
    route: '/',
    active: true,
    // icon: 'fa-light fa-house-blank',
  },
  {
    order: 1,
    title: 'About',
    route: '/about',
    active: true,
  },
  {
    order: 2,
    title: 'Events',
    route: '/' + EStadnickDevPages.RESOURCES + '/' + EStadnickDevPages.EVENTS,
    active: true,
  },
  {
    order: 3,
    title: 'Community',
    route: '/' + EStadnickDevPages.RESOURCES + '/' + EStadnickDevPages.COMMUNITY,
    active: true,
  },
  // {
  //   order: 1,
  //   title: 'Resources',
  //   route: '/' + EStadnickDevPages.RESOURCES,
  //   active: true,
  //   children: [
  //     // {
  //     //   order: 1,
  //     //   title: 'Lessons',
  //     //   route: '/' + EStadnickDevPages.RESOURCES + '/' + EStadnickDevPages.LESSONS,
  //     //   active: true,
  //     // },
  //     {
  //       order: 2,
  //       title: 'Events',
  //       route: '/' + EStadnickDevPages.RESOURCES + '/' + EStadnickDevPages.EVENTS,
  //       active: true,
  //     },
  //     {
  //       order: 3,
  //       title: 'Community',
  //       route: '/' + EStadnickDevPages.RESOURCES + '/' + EStadnickDevPages.COMMUNITY,
  //       active: true,
  //     },
  //   ],
  //   // icon: 'fa-light fa-paperclip',
  // },
  // {
  //   order: 2,
  //   title: 'Blog',
  //   href: 'https://launchpoint.dev/blog?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
  //   active: true,
  //   // icon: 'fa-light fa-file',
  // },
  {
    order: 4,
    title: 'Store',
    route: '/store',
    active: false,
    // icon: 'fa-light fa-cart-shopping',
  },
  {
    order: 5,
    title: 'Contact',
    route: '/' + EStadnickDevPages.BOOK_STRATEGY_CALL,
    active: true,
    // icon: 'fa-light fa-phone',
  },
];

export const SOCIAL_LINKS = [
  {
    icon: 'fa-kit fa-minnect',
    tooltip: 'Minnect',
    href: 'https://app.minnect.com/expert/RobStadnick',
    active: true,
  },
  {
    icon: 'fab fa-instagram',
    tooltip: 'Instagram',
    href: 'https://www.instagram.com/robstadnick/',
    active: true,
  },
  {
    icon: 'fab fa-youtube',
    tooltip: 'YouTube',
    href: 'https://www.youtube.com/@thelaunchpoints',
    active: true,
  },
  {
    icon: 'fab fa-linkedin',
    tooltip: 'LinkedIn',
    href: 'https://www.linkedin.com/in/rob-stadnick-%F0%9F%9A%80-80407573/',
    active: true,
  },
  {
    icon: 'fab fa-facebook',
    tooltip: 'Facebook',
    href: 'https://www.facebook.com/profile.php?id=61567000260346',
    active: true,
  },
  {
    icon: 'fab fa-x-twitter',
    tooltip: 'Twitter',
    href: 'https://x.com/robstadnick',
    active: true,
  },
];

export type ICTA = {
  title: string;
} & RequireOnlyOne<{
  route: string;
  href: string;
}>;

export const PRIMARY_CTA: ICTA = {
  title: "Let's Get To Work",
  route: '/' + EStadnickDevPages.BOOK_STRATEGY_CALL,
  // href: '',
};

export const SECONDARY_CTA: ICTA = {
  title: 'Book a Free Strategy Session',
  route: '/' + EStadnickDevPages.BOOK_STRATEGY_CALL,
  // href: 'https://launchpoint.dev/get-started?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
};
// export const SECONDARY_CTA = {
//   title: 'Launchpoint',
//   // route: '/' + ELaunchpointPages.RESOURCES,
//   href: 'https://launchpoint.dev?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
// };

export const FOOTER_MENU: IMenu[] = [
  {
    order: 0,
    title: 'Information',
    href: '',
    active: true,
    children: [
      {
        order: 0,
        title: 'Home',
        route: '/',
        active: true,
      },
      {
        order: 1,
        title: 'Blog',
        href: 'https://launchpoint.dev/blog?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
        active: true,
      },
    ],
  },
  {
    order: 1,
    title: 'Resources',
    href: '',
    active: true,
    children: [
      {
        order: 0,
        title: 'All Resources',
        active: true,
        href: 'https://launchpoint.dev/resources?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
      },
      {
        order: 1,
        title: 'Community',
        active: true,
        href: 'https://launchpoint.dev/resources/community?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
      },
    ],
  },
  {
    order: 2,
    title: 'Blog',
    href: 'https://launchpoint.dev/blog?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
    active: true,
    children: [
      {
        order: 0,
        title: 'What Is Lag? What To Do About It',
        active: true,
        href: 'https://launchpoint.dev/blog/what-is-business-lag-and-what-to-do-about-it?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
      },
      {
        order: 1,
        title: 'The Product Life Cycle',
        href: 'https://launchpoint.dev/blog/the-product-life-cycle-from-cold-lead-to-customer-retention?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
        active: true,
      },
      {
        order: 2,
        title: 'Less Hours, More Results',
        href: 'https://launchpoint.dev/blog/more-hours-doesnt-equal-more-business-results?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
        active: true,
      },
      {
        order: 3,
        title: '5 Blog Links In Your Footer',
        href: 'https://launchpoint.dev/blog/why-you-should-have-5-blog-posts-on-your-website-and-have-them-in-the-footer?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
        active: true,
      },
      {
        order: 4,
        title: 'Embrace Digital Transformation',
        href: 'https://launchpoint.dev/blog/embracing-digital-transformation-in-your-business?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
        active: true,
      },
    ],
  },
];
