// IManychatFlowResponse Interface
export interface IManychatFlowResponse {
  status: 'success';
  data: null;
}

// IManychatSendContentPayload Interface
export interface IManychatSendContentPayload {
  subscriber_id: string;
  data: IManychatMessageData;
  message_tag?: string;
  otn_topic_name?: string;
}

// IManychatSendContentByUserRefPayload Interface
export interface IManychatSendContentByUserRefPayload {
  user_ref: string;
  data: IManychatMessageData;
}

// IManychatMessageData Interface
export interface IManychatMessageData {
  text?: string;
  image_url?: string;
  action?: string;
  buttons?: IManychatButton[];
  // Add other fields depending on message type requirements
}

// IManychatButton Interface
export interface IManychatButton {
  type: 'url' | 'postback' | 'phone_number';
  title: string;
  payload?: string; // Used for postback actions
  url?: string; // Used for URL actions
  phone_number?: string; // Used for phone number actions
}
