import { Directive, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@launchpoint-app/client';
import { EStadnickDevPages } from '../../../config/pages';
import { ManyChatSubscribeService } from '@launchpoint-app/client';
import { LaunchpointResourcesEventsService } from '../../../services/resources.service';

@Directive()
export class ResourcesBaseComponent extends BaseComponent implements OnInit {
  _LaunchpointResourcesEventsService = inject(LaunchpointResourcesEventsService);
  _ManyChatSubscribeService = inject(ManyChatSubscribeService);
  _Router = inject(Router);
  route = inject(ActivatedRoute);
  LaunchpointPages = EStadnickDevPages;

  ngOnInit() {
    this._LaunchpointResourcesEventsService.getResources();
  }
}
