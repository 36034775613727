// IManychatSubscriber Data Interface
export interface IManychatSubscriber {
  /** Unique identifier of the subscriber */
  id: string;
  /** Facebook Page ID associated with the subscriber */
  page_id: string;
  /** Array containing subscriber reference fields */
  user_refs: IManychatSubscriberRefField[];
  /** First name of the subscriber */
  first_name?: string;
  /** Last name of the subscriber */
  last_name?: string;
  /** Full name of the subscriber */
  name?: string;
  /** Gender of the subscriber */
  gender?: string;
  /** URL to the subscriber's profile picture */
  profile_pic?: string;
  /** Locale of the subscriber */
  locale?: string;
  /** Language of the subscriber */
  language?: string;
  /** Timezone of the subscriber */
  timezone?: string;
  /** URL for live chat with the subscriber */
  live_chat_url?: string;
  /** Last input text from the subscriber */
  last_input_text?: string;
  /** Whether the subscriber opted into phone notifications */
  optin_phone?: boolean;
  /** Phone number of the subscriber */
  phone?: string;
  /** Whether the subscriber opted into email notifications */
  optin_email?: boolean;
  /** Email address of the subscriber */
  email?: string;
  /** Subscription datetime in W3C format */
  subscribed?: string;
  /** Last interaction datetime in W3C format; nullable */
  last_interaction?: string | null;
  /** Last seen datetime in W3C format */
  last_seen?: string;
  /** Whether follow-up messaging is enabled */
  is_followup_enabled?: boolean;
  /** Instagram username of the subscriber */
  ig_username?: string;
  /** Instagram ID of the subscriber */
  ig_id?: number;
  /** WhatsApp phone number of the subscriber */
  whatsapp_phone?: string;
  /** Whether the subscriber opted into WhatsApp notifications */
  optin_whatsapp?: boolean;
  /** Array of custom fields associated with the subscriber */
  custom_fields?: IManychatSubscriberCustomField[];
  /** Array of tags associated with the subscriber */
  tags?: IManyChatTag[];
}

// IManychatCreateSubscriberPayload Interface
export interface IManychatCreateSubscriberPayload {
  /** First name of the subscriber */
  first_name?: string;
  /** Last name of the subscriber */
  last_name?: string;
  /** Phone number, required if `email` and `whatsapp_phone` are empty */
  phone?: string;
  /** WhatsApp phone number, required if `email` and `phone` are empty */
  whatsapp_phone?: string;
  /** Email address, required if `phone` and `whatsapp_phone` are empty */
  email?: string;
  /** Gender of the subscriber */
  gender?: string;
  /** Whether SMS opt-in is provided, required if `phone` is present */
  has_opt_in_sms?: boolean;
  /** Whether email opt-in is provided, required if `email` is present */
  has_opt_in_email?: boolean;
  /** Consent phrase required if `has_opt_in_sms` is true */
  consent_phrase?: string;
}

// IManychatUpdateSubscriberPayload Interface
export interface IManychatUpdateSubscriberPayload {
  /** Unique identifier of the subscriber */
  subscriber_id: string;
  /** First name of the subscriber */
  first_name?: string;
  /** Last name of the subscriber */
  last_name?: string;
  /** Phone number of the subscriber */
  phone?: string;
  /** Email address of the subscriber */
  email?: string;
  /** Gender of the subscriber */
  gender?: string;
  /** Whether SMS opt-in is provided, required if `phone` is present */
  has_opt_in_sms?: boolean;
  /** Whether email opt-in is provided, required if `email` is present */
  has_opt_in_email?: boolean;
  /** Consent phrase required if `has_opt_in_sms` is true */
  consent_phrase?: string;
}

// Additional Interfaces
export interface IManychatSubscriberRefField {
  /** Reference string of the subscriber */
  user_ref: string;
  /** Datetime in W3C format representing the opt-in time */
  opted_in: string;
}

export interface IManychatSubscriberCustomField {
  /** Unique identifier of the custom field */
  id: number;
  /** Name of the custom field */
  name: string;
  /** Type of the custom field, one of 'text', 'number', 'date', 'datetime', or 'boolean' */
  type: 'text' | 'number' | 'date' | 'datetime' | 'boolean';
  /** Optional description of the custom field */
  description?: string;
  /** Value of the custom field; type depends on the custom field type */
  value?: string | number | boolean;
}

export interface IManyChatTag {
  /** Unique identifier of the tag */
  id: number;
  /** Name of the tag */
  name: string;
}

// Response Interfaces
export interface IManychatSuccessResponse<T> {
  /** Response status, expected to be 'success' */
  status: 'success';
  /** Data returned by the response */
  data: T;
}

export interface IManychatErrorResponse {
  /** Response status, expected to be 'error' */
  status: 'error';
  /** Error message explaining the reason for failure */
  message: string;
  /** Additional details about the error */
  details?: { messages: IManychatErrorMessage[] };
}

export interface IManychatErrorMessage {
  /** Error message string */
  message: string;
}
