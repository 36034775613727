<div class="relative flex flex-col lg:flex-row">
  <div class="lg:w-1/2"><img src="./assets/img/stadnick.webp" class="h-[400px] w-full object-cover md:h-[850px]" alt="rob stadnick" /></div>
  <div class="relative lg:w-1/2">
    <!-- Background Blur -->
    <div class="absolute inset-0 bg-[#D9934260] backdrop-blur-md"></div>

    <!-- Image -->
    <img src="./assets/img/quote-img.webp" class="h-[400px] w-full object-cover md:h-[850px]" alt="laptop computer" />

    <!-- Text Overlay -->
    <div class="absolute inset-0 flex items-center justify-center px-10 text-center">
      <p class="font-oswald flex flex-col px-4 text-[18px] font-semibold uppercase text-white md:text-[30px] lg:text-[40px]">
        <i class="fa-sharp fa-solid fa-quote-left text-5xl text-black md:text-[100px]"></i>
        I don't believe in waiting <br />
        for resources or opportunities; <br />
        I believe in creating them, <br />
        from the ground up.
      </p>
    </div>
  </div>
  <div
    class="bg-primary absolute left-1/2 top-1/2 z-10 flex h-[85px] w-[85px] -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full md:h-[158px] md:w-[158px]"
  >
    <img src="./assets/img/rob-brand.webp" class="mb-2 h-[50px] md:h-[95px]" alt="rob brand logo" />
  </div>
</div>
