import { computed, inject, Injectable, signal } from '@angular/core';
import { CircleEventHTTPService } from '@launchpoint-app/client';
import { ICircleEvent, ICircleEventSettingAttributesInPersonLocation } from '@launchpoint-app/types';
import { ILaunchpointSearchFilterDates } from '@launchpoint/core-types';
import { first } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LaunchpointResourcesEventsService {
  all_events = signal<ICircleEvent[]>([]);
  loading = signal<boolean>(true);
  selected_event = signal<ICircleEvent | null>(null);

  _CircleEventHTTPService = inject(CircleEventHTTPService);
  searchDates = signal<ILaunchpointSearchFilterDates>({ start_search_date: null, end_search_date: null });

  filtered = computed(() => {
    const { start_search_date, end_search_date } = this.searchDates();

    return this.all_events().filter((event) => {
      const eventDate = new Date(event.event_setting_attributes.starts_at);

      const matchesStart = !start_search_date || eventDate >= start_search_date;
      const matchesEnd = !end_search_date || eventDate <= end_search_date;

      // console.log('Filtered Event:', event); // Log only if needed for debugging

      return matchesStart && matchesEnd;
    });
  });

  getLocation(data: string): ICircleEventSettingAttributesInPersonLocation {
    let obj: ICircleEventSettingAttributesInPersonLocation;

    try {
      // console.log({ data });
      // console.log('data:', typeof data);
      obj = JSON.parse(data);
      // console.log({ obj });
    } catch (error) {
      console.error(error);
    }

    return obj;
  }

  getResources() {
    this.loading.set(true);
    this._CircleEventHTTPService
      .listEventsPublic()
      .pipe(first())
      .subscribe({
        next: (response) => {
          // console.group('events');
          // console.log({ response });
          // console.log({ events: this._LaunchpointResourcesEventsService.all_events() });

          this.all_events.set(response.records);

          this.loading.set(false);
          // console.log({ events_after: this._LaunchpointResourcesEventsService.all_events() });
          // console.groupEnd();
        },
        error: (err) => {
          this.all_events.set([]);
          this.loading.set(false);
        },
      });
  }

  setSelectedByTitle(title_url: string) {
    const blog = this.all_events().find((f) => f.name === title_url);
    if (blog) {
      this.selected_event.set(blog);
    } else {
      this.selected_event.set(null);
    }
  }
}
