import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CircleEventHTTPService } from '@launchpoint-app/client';
import { CtaBannerComponent } from '../../components/common/cta-banner/cta-banner.component';
import { SocialIconsComponent } from '../../components/common/social-icons/social-icons.component';
import { StadnickSuccessStoriesComponent } from '../../components/success-stories/success-stories.component';
import { StadnickDevConfigService } from '../../services/config.service';
import { LaunchpointResourcesEventsService } from '../../services/resources.service';
import { HOME_COMPONENTS } from './components';
import { HomeComponent } from './home.component';

const STANDALONE_COMPONENTS = [CtaBannerComponent, SocialIconsComponent, StadnickSuccessStoriesComponent];

@NgModule({
  imports: [CommonModule, NgOptimizedImage, RouterModule, ...STANDALONE_COMPONENTS],
  declarations: [HomeComponent, ...HOME_COMPONENTS],
  providers: [CircleEventHTTPService, LaunchpointResourcesEventsService, StadnickDevConfigService],
})
export class HomePageModule {}
