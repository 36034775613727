import {
  ELaunchpointUserRoles,
  ESecurityCoreCompanyResources,
  ESecurityCoreUserRoleResources,
  ICoreCompany,
  ICoreUser,
  ISecurityConfig,
  ISecurityConfigACLRole,
  ICoreUserJWTFlat,
  LAUNCHPOINT_COMPANY_SCHEMA,
  SECURITY_PERMISSIONS_DEFAULT_ROLE,
} from '@launchpoint/core-types';

export const DEFAULT_ROLE_COMPANY: ISecurityConfigACLRole<ICoreUserJWTFlat, ICoreCompany>[] = [
  {
    effect: 'can',
    permissions: [
      {
        actions: [ESecurityCoreCompanyResources.COMPANY_CREATE],
        subject: LAUNCHPOINT_COMPANY_SCHEMA,
      },
    ],
    // conditions: [{ _id: '${account_id}' }],
  },
  {
    effect: 'can',
    permissions: [
      {
        actions: [ESecurityCoreCompanyResources.COMPANY_READ, ESecurityCoreCompanyResources.COMPANY_SEARCH],
        subject: LAUNCHPOINT_COMPANY_SCHEMA,
      },
    ],
    conditions: [{ _id: '${account_id}' }],
  },
  {
    effect: 'can',
    permissions: [
      {
        actions: [
          ESecurityCoreCompanyResources.COMPANY_DELETE,
          ESecurityCoreCompanyResources.COMPANY_UPDATE,
          ESecurityCoreCompanyResources.COMPANY_DEACTIVATE,
          ESecurityCoreCompanyResources.COMPANY_REPORTING,
        ],
        subject: LAUNCHPOINT_COMPANY_SCHEMA,
      },
    ],
    conditions: [{ 'account_owner._id': '${user_id}' }],
  },
];

export const COMPANY_ABAC: ISecurityConfig<ICoreUserJWTFlat, ICoreUser> = {
  debug: false,
  acl: {
    [SECURITY_PERMISSIONS_DEFAULT_ROLE]: DEFAULT_ROLE_COMPANY,
    // add any other specific user roles here
    [ELaunchpointUserRoles.USER]: DEFAULT_ROLE_COMPANY,
    [ELaunchpointUserRoles.ADMIN]: [
      {
        effect: 'can',
        permissions: [
          {
            actions: [...Object.values(ESecurityCoreCompanyResources), ...Object.values(ESecurityCoreUserRoleResources)],
            subject: LAUNCHPOINT_COMPANY_SCHEMA,
          },
        ],
        conditions: [{ security_roles: { $ne: 'super_admin' } }],
      },
    ],
    [ELaunchpointUserRoles.SUPER_ADMIN]: [
      {
        effect: 'can',
        permissions: [{ subject: LAUNCHPOINT_COMPANY_SCHEMA, actions: ['manage'] }],
      },
    ],
  },
};
