import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@launchpoint-app/client';
import { LaunchpointResourcesEventsService } from '../../../../services/resources.service';

@Component({
  selector: 'sd-events',
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss',
})
export class EventsComponent extends BaseComponent implements OnInit {
  _LaunchpointResourcesEventsService = inject(LaunchpointResourcesEventsService);
  _Router = inject(Router);
  route = inject(ActivatedRoute);

  ngOnInit() {
    this._LaunchpointResourcesEventsService.getResources();
  }

  get events() {
    return this._LaunchpointResourcesEventsService.filtered;
  }

  get loading() {
    return this._LaunchpointResourcesEventsService.loading;
  }

  location(data) {
    return this._LaunchpointResourcesEventsService.getLocation(data);
  }
}
