import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { ILaunchpointDynamicFormResponseAnalyticsController, ILaunchpointDynamicFormResponseStatusAnalytics } from '@launchpoint-app/types';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig, LaunchpointSecurityAccountIdHeaderServiceV2 } from '@launchpoint/core-client';
import { configureURL, ICoreCreatedAtAnalytics, IDateRange } from '@launchpoint/core-types';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class LaunchpointDynamicFormResponseAnalyticsV1Service
  extends LaunchpointSecurityAccountIdHeaderServiceV2
  implements ILaunchpointDynamicFormResponseAnalyticsController
{
  http = inject(HttpClient);
  API_URL = '/api/backend/';

  constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _CoreAuthConfig: ICoreAuthConfig) {
    super();

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _CoreAuthConfig.auth?.base_url,
      version: 1,
      route: 'response-analytics',
    });
  }
  public created(params: IDateRange): Observable<ICoreCreatedAtAnalytics> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreCreatedAtAnalytics>(this.API_URL, params, { headers });
      })
    );
  }

  public status(): Observable<ILaunchpointDynamicFormResponseStatusAnalytics[]> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ILaunchpointDynamicFormResponseStatusAnalytics[]>(this.API_URL + '/status', { headers });
      })
    );
  }
}
