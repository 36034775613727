import { ELPDefaultActions, ILaunchpointActions } from '@launchpoint/core-types';
import { ILaunchpointDynamicForm } from './form.interface';

export enum ELaunchpointDynamicForm { // These equal the controllers
  FORM = 'form',
  ACCOUNTS = 'form-accounts',
  USERS = 'form-users',
  GROUPS = 'form-groups',
  QUESTIONS = 'form-groups-questions',
  COMPUTED_FIELDS = 'forms-computed-fields',
}

export enum ELaunchpointDynamicFormActions { // Also sometimes called resources
  // Base Service Actions
  BASE_SEARCH = ELaunchpointDynamicForm.FORM + '/' + ELPDefaultActions.SEARCH,
  BASE_GET_BY_ID = ELaunchpointDynamicForm.FORM + '/' + ELPDefaultActions.GET_BY_ID,
  BASE_CREATE = ELaunchpointDynamicForm.FORM + '/' + ELPDefaultActions.CREATE,
  BASE_UPDATE = ELaunchpointDynamicForm.FORM + '/' + ELPDefaultActions.UPDATE,
  BASE_UPDATE_BULK = ELaunchpointDynamicForm.FORM + '/' + ELPDefaultActions.UPDATE_BULK,
  BASE_DELETE = ELaunchpointDynamicForm.FORM + '/' + ELPDefaultActions.DELETE,
  BASE_DELETE_BULK = ELaunchpointDynamicForm.FORM + '/' + ELPDefaultActions.DELETE_BULK,
  BASE_ARCHIVE = ELaunchpointDynamicForm.FORM + '/' + ELPDefaultActions.ARCHIVE,
  BASE_ARCHIVE_BULK = ELaunchpointDynamicForm.FORM + '/' + ELPDefaultActions.ARCHIVE_BULK,
  ACCOUNTS_ADD = ELaunchpointDynamicForm.ACCOUNTS + '/' + ELPDefaultActions.CREATE,
  ACCOUNTS_REMOVE = ELaunchpointDynamicForm.ACCOUNTS + '/' + ELPDefaultActions.DELETE,
  USERS_ADD = ELaunchpointDynamicForm.USERS + '/' + ELPDefaultActions.CREATE,
  USERS_REMOVE = ELaunchpointDynamicForm.USERS + '/' + ELPDefaultActions.DELETE,
  GROUPS_ADD = ELaunchpointDynamicForm.GROUPS + '/' + ELPDefaultActions.CREATE,
  GROUPS_REORDER = ELaunchpointDynamicForm.GROUPS + '/' + ELPDefaultActions.REORDER,
  GROUPS_UPDATE = ELaunchpointDynamicForm.GROUPS + '/' + ELPDefaultActions.UPDATE,
  GROUPS_REMOVE = ELaunchpointDynamicForm.GROUPS + '/' + ELPDefaultActions.DELETE,
  QUESTIONS_ADD = ELaunchpointDynamicForm.QUESTIONS + '/' + ELPDefaultActions.CREATE,
  QUESTIONS_UPDATE = ELaunchpointDynamicForm.QUESTIONS + '/' + ELPDefaultActions.UPDATE,
  QUESTIONS_REORDER = ELaunchpointDynamicForm.QUESTIONS + '/' + ELPDefaultActions.REORDER,
  /**
   * How should this be done? The is the only action that seems specific to this feature alone.
   */
  QUESTIONS_CHANGE_GROUP = ELaunchpointDynamicForm.QUESTIONS + '/' + 'group/change',
  QUESTIONS_REMOVE = ELaunchpointDynamicForm.QUESTIONS + '/' + ELPDefaultActions.DELETE,
  COMPUTED_FIELDS_ADD = ELaunchpointDynamicForm.COMPUTED_FIELDS + '/' + ELPDefaultActions.CREATE,
  COMPUTED_FIELDS_UPDATE = ELaunchpointDynamicForm.COMPUTED_FIELDS + '/' + ELPDefaultActions.UPDATE,
  COMPUTED_FIELDS_REORDER = ELaunchpointDynamicForm.COMPUTED_FIELDS + '/' + ELPDefaultActions.REORDER,
  COMPUTED_FIELDS_REMOVE = ELaunchpointDynamicForm.COMPUTED_FIELDS + '/' + ELPDefaultActions.DELETE,
}

export type ILaunchpointFormActionsJSON = ILaunchpointActions<ELaunchpointDynamicFormActions, ILaunchpointDynamicForm>;

export const LaunchpointFormActionsJSON: ILaunchpointFormActionsJSON[] = [
  {
    action: ELaunchpointDynamicFormActions.BASE_UPDATE,
    title: 'Open',
    conditions: {}, // TODO:  Add conditions for actions
    active: false,
  },
];
