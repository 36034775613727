import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ISEOData, ISEOToken } from '@launchpoint/core-types';
import { filter } from 'rxjs';
import { SEO_CONFIG_TOKEN } from './seo.interface';

@Injectable({
  providedIn: 'root',
})
export class LaunchpointSEORouterService {
  titleService = inject(Title);
  metaService = inject(Meta);
  router = inject(Router);
  debug = false;
  seoOptions: ISEOToken = inject(SEO_CONFIG_TOKEN);

  private isBrowser: boolean;
  private canonicalLink: HTMLLinkElement;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    if (this.debug === false) {
      this.debug = this.seoOptions.debug ?? false;
    }

    this.isBrowser = isPlatformBrowser(this.platformId);

    // this.canonicalLink.setAttribute('href', 'https://launchpoint.dev/');

    // Add it to the DOM
    // this.document.head.append(this.canonicalLink);
  }

  public setCanonicalUrl(url: string) {
    if (this.isBrowser) {
      try {
        const canonicalHref = url || window.location.origin + window.location.pathname;

        // Check if a canonical link already exists
        this.canonicalLink = document.querySelector('link[rel="canonical"]');

        if (this.canonicalLink) {
          // Update the existing canonical link
          this.canonicalLink.setAttribute('href', canonicalHref);
          if (this.debug) console.log('Updated canonical link to:', canonicalHref);
        } else {
          // Create a new canonical link
          this.canonicalLink = document.createElement('link');
          this.canonicalLink.setAttribute('rel', 'canonical');
          this.canonicalLink.setAttribute('href', canonicalHref);
          document.head.appendChild(this.canonicalLink);
          if (this.debug) console.log('Created new canonical link:', canonicalHref);
        }
      } catch (error) {
        console.error('Error setting canonical link:', error);
      }
    } else {
      console.log('ON SERVER');
    }
  }

  public register() {
    // seoOptions?: ISEOOptions
    if (this.debug) console.log('LaunchpointSEOService Registered');
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
      next: () => this.updateTitleAndMeta(),
      error: (error) => console.error(error),
    });
  }

  findSEODataByRoute(route: string): ISEOData {
    for (const key in this.seoOptions.seo) {
      if (this.seoOptions.seo[key].route === route) {
        return this.seoOptions.seo[key];
      }
    }
    return this.seoOptions.seo['home'];
  }

  updateTitleAndMeta(seoDataOverride?: Partial<ISEOData>): void {
    // console.log('updateTitleAndMeta');
    const route = this.router.url.split('?')[0].substring(1) || 'home';
    if (this.isBrowser) {
      try {
        this.setCanonicalUrl(document.URL);
      } catch (error) {
        console.error('Error setting canonical link:', error);
      }
    } else {
      console.log('ON SERVER');
    }
    const defaultData = this.seoOptions.seo['home'];
    const routeData = this.findSEODataByRoute(route);
    const seoData = { ...defaultData, ...routeData, ...seoDataOverride };

    if (this.debug) {
      console.log('Route:', route);
      if (seoDataOverride) {
        console.log('SEO Data Override:');
      }
      console.log('SEO Data:', seoData);
    }

    this.titleService.setTitle(seoData.title);
    this.metaService.updateTag({ name: 'description', content: seoData.description });

    if (seoData.keywords) {
      this.metaService.updateTag({ name: 'keywords', content: seoData.keywords });
    }

    // if (seoData.canonical_url) {
    //   this.metaService.updateTag({ rel: 'canonical', href: seoData.canonical_url });
    // }

    if (seoData?.og?.title) {
      this.metaService.updateTag({ property: 'og:title', content: seoData.og.title });
    }
    if (seoData?.og?.description) {
      this.metaService.updateTag({ property: 'og:description', content: seoData.og.description });
    }
    if (seoData?.og?.image) {
      this.metaService.updateTag({ property: 'og:image', content: seoData.og.image });
    }
    if (seoData?.og?.url) {
      this.metaService.updateTag({ property: 'og:url', content: seoData.og.url });
    }
    if (seoData?.og?.type) {
      this.metaService.updateTag({ property: 'og:type', content: seoData.og.type });
    }
    if (seoData?.og?.site_name) {
      this.metaService.updateTag({ property: 'og:site_name', content: seoData.og.site_name });
    }
    if (seoData?.og?.locale) {
      this.metaService.updateTag({ property: 'og:locale', content: seoData.og.locale });
    }

    // Update Twitter Card tags
    if (seoData?.twitter?.card) {
      this.metaService.updateTag({ name: 'twitter:card', content: seoData.twitter.card });
    }
    if (seoData?.twitter?.site) {
      this.metaService.updateTag({ name: 'twitter:site', content: seoData.twitter.site });
    }
    if (seoData?.twitter?.title) {
      this.metaService.updateTag({ name: 'twitter:title', content: seoData.twitter.title });
    }
    if (seoData?.twitter?.description) {
      this.metaService.updateTag({ name: 'twitter:description', content: seoData.twitter.description });
    }
    if (seoData?.twitter?.image) {
      this.metaService.updateTag({ name: 'twitter:image', content: seoData.twitter.image });
    }

    if (seoData.author) {
      this.metaService.updateTag({ name: 'author', content: seoData.author });
    }

    if (seoData.robots) {
      this.metaService.updateTag({ name: 'robots', content: seoData.robots });
    }

    if (seoData.published_time) {
      this.metaService.updateTag({ property: 'article:published_time', content: seoData.published_time });
    }

    if (seoData.modified_time) {
      this.metaService.updateTag({ property: 'article:modified_time', content: seoData.modified_time });
    }

    if (seoData.language) {
      this.metaService.updateTag({ name: 'language', content: seoData.language });
    }

    if (seoData.theme_color) {
      this.metaService.updateTag({ name: 'theme-color', content: seoData.theme_color, media: '(prefers-color-scheme: light)' });
      this.metaService.updateTag({ name: 'theme-color', content: seoData.theme_color, media: '(prefers-color-scheme: dark)' });
    }

    if (seoData.ms_tile_color) {
      this.metaService.updateTag({ name: 'msapplication-TileColor', content: seoData.ms_tile_color });
    }
  }
}
