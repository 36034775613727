import { ICoreCompany } from '@launchpoint/core-types';
import {
  ILaunchpointPaymentsConnectAccount,
  ILaunchpointPaymentsCustomer,
  ILaunchpointPaymentsModelIdsBase,
  PAYMENTS_MODEL_POPULATE_ARRAY,
} from '@launchpoint/payments-types';
import { PopulateOptions } from 'mongoose';

export type ICompanyBase = ICoreCompany & Omit<ILaunchpointPaymentsModelIdsBase, 'subscription'>;

export interface ICompany extends ICompanyBase {
  customer_id: ILaunchpointPaymentsCustomer;
  connect_account: ILaunchpointPaymentsConnectAccount;
}

export const POPULATE_COMPANY: PopulateOptions[] = [
  ...PAYMENTS_MODEL_POPULATE_ARRAY,
  {
    path: 'customer_id',
    localField: 'customer_id',
    foreignField: 'id',
    strictPopulate: false,
  },
  {
    path: 'account_owner',
    // select: { profile: 1, email: 1 }
  },
  // {
  //   path: 'pinpoint_application_id',
  //   localField: 'pinpoint_application_id',
  //   foreignField: 'id',
  //   strictPopulate: false,
  // },
  // {
  //   path: 'connect_account',
  //   localField: 'connect_account',
  //   foreignField: 'id',
  //   strictPopulate: false,
  // },
];
