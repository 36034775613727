import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent, ManyChatSubscribeService } from '@launchpoint-app/client';
import { GoogleAnalyticsService, ManychatPixelService } from '@launchpoint/core-web';
import { takeUntil } from 'rxjs';
import { SocialIconsComponent } from '../../components/common/social-icons/social-icons.component';
import { StadnickNewsletterComponent } from '../../components/newsletter/newsletter.component';

@Component({
  selector: 'sd-book-strategy-call',
  standalone: true,
  imports: [CommonModule, StadnickNewsletterComponent, SocialIconsComponent],
  providers: [ManyChatSubscribeService],
  templateUrl: 'book-strategy-call.component.html',
  styleUrls: ['book-strategy-call.component.scss'],
})
export class BookStrategyCallComponent extends BaseComponent implements AfterViewInit, OnInit, OnDestroy {
  name = '';
  email = '';
  phone = '';
  company = '';

  contactInfo: { icon: string; text: string }[] = [
    // {
    //   text: 'request@stadnick.dev',
    //   icon: 'fa-solid fa-envelope',
    // },
    {
      text: '+1-208-567-9374',
      icon: 'fa-solid fa-phone',
    },
    {
      text: 'Boise, ID',
      icon: 'fa-solid fa-map-marker',
    },
  ];

  booked$ = signal<boolean>(false);

  private route = inject(ActivatedRoute);
  _GoogleAnalyticsService = inject(GoogleAnalyticsService);
  _ManychatPixelService = inject(ManychatPixelService);

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe({
      next: (params) => {
        const allParams = {};
        for (const key in params) {
          if (Object.prototype.hasOwnProperty.call(params, key)) {
            // console.log('Key', key);
            const element = params[key] as string;
            // console.log('value', element);
            if (element.includes('{{')) {
              allParams[key] = null;
            } else {
              allParams[key] = params[key];
            }
          }
        }
        // console.log({ allParams });
        this.name = allParams['name'] ?? '';
        this.email = allParams['email'] ?? '';
        this.phone = allParams['phone'] ?? '';
        this.company = allParams['company'] ?? '';
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  ngAfterViewInit(): void {
    const calendlyUrl = this.buildCalendlyUrl();
    this.updateCalendlyWidget(calendlyUrl);
    this.loadCalendlyScript();
    this.listenToCalendlyEvents();
  }

  private buildCalendlyUrl(): string {
    return `https://calendly.com/lp-account-executives/discovery-call?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=010424&name=${encodeURIComponent(this.name)}&email=${encodeURIComponent(this.email)}&a1=${encodeURIComponent(this.phone)}&a2=${encodeURIComponent(this.company)}`;
  }

  private updateCalendlyWidget(url: string): void {
    const widgetDiv = document.querySelector('.calendly-inline-widget');
    if (widgetDiv) {
      widgetDiv.setAttribute('data-url', url);
    }
  }

  private loadCalendlyScript(): void {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  }

  private listenToCalendlyEvents(): void {
    try {
      window.addEventListener('message', (event) => {
        // console.log('Calendly event:', event);
        if (event.origin === 'https://calendly.com') {
          const eventData = event.data.event;

          switch (eventData) {
            case 'calendly.profile_page_viewed':
              // console.log('Profile page viewed.');
              break;
            case 'calendly.event_type_viewed':
              // console.log('Event type viewed.');
              break;
            case 'calendly.date_and_time_selected':
              // console.log('Date and time selected.');
              break;
            case 'calendly.event_scheduled':
              // console.log('Event scheduled.');
              this.booked$.set(true);
              this._ManychatPixelService.trackConversionEvent('Booked Call');
              this._GoogleAnalyticsService.trackEvent('Booked Call', JSON.stringify({ name: this.name, email: this.email }), 'Marketing');
              break;
            case 'calendly.booking_completed':
              // console.log('Booking completed.');
              this.booked$.set(true);
              this._ManychatPixelService.trackConversionEvent('Booked Call');
              this._GoogleAnalyticsService.trackEvent('Booked Call', JSON.stringify({ name: this.name, email: this.email }), 'Marketing');
              break;
            default:
            // console.log('Unknown Calendly event:', eventData);
          }
        }
      });
    } catch (error) {
      console.error('Error handling Calendly events', error);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    window.removeEventListener('message', this.listenToCalendlyEvents as any);
  }
}
