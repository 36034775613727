import { ILaunchpointDynamicFormResponseSearchQuery } from '@launchpoint-app/types';
import { IHttpException, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as LaunchpointDynamicFormResponseActions from '../actions/actions';
import { LaunchpointDynamicFormResponseEntityState } from '../interface/interface';
import { DYNAMIC_FORM_RESPONSES_YEAR_REDUCER } from './years/years-reducer';

export const LaunchpointDynamicFormResponseFeatureKey = 'LaunchpointDynamicFormResponseReducerKey';

export interface LaunchpointDynamicFormResponseState extends EntityState<LaunchpointDynamicFormResponseEntityState> {
  selected_dynamic_form_response_id: string;
  loaded: boolean;
  loading: boolean;
  year_1: number; // Previous year
  year_2: number; // Two years ago
  years: number[];
  primaryForm: {
    form_id: string;
    year_question_id: string;
  };
  error: IHttpException | null;
  success_message: string | null;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: ILaunchpointDynamicFormResponseSearchQuery;
}

export const LaunchpointDynamicFormResponseEntityAdaptor: EntityAdapter<LaunchpointDynamicFormResponseEntityState> =
  createEntityAdapter<LaunchpointDynamicFormResponseEntityState>({
    selectId: (dynamic_form) => dynamic_form.dynamic_form_response_id,
  });

export const initialLaunchpointDynamicFormResponseState: LaunchpointDynamicFormResponseState =
  LaunchpointDynamicFormResponseEntityAdaptor.getInitialState({
    // set initial required properties
    selected_dynamic_form_response_id: '',
    year_1: new Date().getFullYear() - 2, // Previous year
    year_2: new Date().getFullYear() - 1, // Two years ago
    years: [
      new Date().getFullYear() - 1,
      new Date().getFullYear() - 2,
      new Date().getFullYear() - 3,
      new Date().getFullYear() - 4,
      new Date().getFullYear() - 5,
      new Date().getFullYear() - 6,
    ],
    primaryForm: {
      form_id: '66130dbd0eaf52e790e8dc7d',
      year_question_id: '6615e673a3e3bd051156b30f',
    },
    loaded: false,
    loading: false,
    pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
    querySort: { created_at: -1 },
    query: { search: '' },
    error: null,
    success_message: null,
  });

export const LaunchpointDynamicFormResponseReducer = createReducer(
  initialLaunchpointDynamicFormResponseState,
  ...DYNAMIC_FORM_RESPONSES_YEAR_REDUCER,
  on(LaunchpointDynamicFormResponseActions.searchAllLaunchpointDynamicFormResponse, (state) => {
    return LaunchpointDynamicFormResponseEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(LaunchpointDynamicFormResponseActions.searchAllLaunchpointDynamicFormResponseSuccess, (state, { data }) => {
    const DynamicFormEntities = data.data.map((dynamic_form_response) => {
      const DynamicFormEntity: LaunchpointDynamicFormResponseEntityState = {
        dynamic_form_response_id: dynamic_form_response._id,
        dynamic_form_response: dynamic_form_response,
        loaded: true,
        loading: false,
        error: null,
      };
      return DynamicFormEntity;
    });

    return LaunchpointDynamicFormResponseEntityAdaptor.addMany(DynamicFormEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(LaunchpointDynamicFormResponseActions.searchAllLaunchpointDynamicFormResponseFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(LaunchpointDynamicFormResponseActions.updateAllLaunchpointDynamicFormResponseQuery, (state, { query }) => {
    return LaunchpointDynamicFormResponseEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),

  // COMPANY USERS ACTIONS

  on(LaunchpointDynamicFormResponseActions.updateAllLaunchpointDynamicFormResponsePagination, (state, { pagination }) => {
    return LaunchpointDynamicFormResponseEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(LaunchpointDynamicFormResponseActions.updateAllLaunchpointDynamicFormResponsePaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loaded: false,
    loading: true,
    // error: null,
    success_message: null,
  })),
  on(LaunchpointDynamicFormResponseActions.updateAllLaunchpointDynamicFormResponseSorting, (state, { querySort }) => {
    return LaunchpointDynamicFormResponseEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(LaunchpointDynamicFormResponseActions.getLaunchpointDynamicFormResponseById, (state, { data_id }) => ({
    ...state,
    selected_data_id: data_id,
    loaded: false,
    loading: false,
    // error: null,
    success_message: null,
  })),
  on(LaunchpointDynamicFormResponseActions.getLaunchpointDynamicFormResponseByIdSuccess, (state, { data }) => {
    const entity: LaunchpointDynamicFormResponseEntityState = {
      dynamic_form_response_id: data._id,
      dynamic_form_response: data,
      loaded: true,
      loading: false,
      error: null,
    };
    return LaunchpointDynamicFormResponseEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_data_id: data._id,
      loaded: true,
      loading: false,
    });
  }),
  on(LaunchpointDynamicFormResponseActions.getLaunchpointDynamicFormResponseByIdFailure, (state, { data_id, error }) => {
    return LaunchpointDynamicFormResponseEntityAdaptor.updateOne(
      {
        id: data_id,
        changes: {
          error,
          loaded: true,
          loading: false,
        },
      },
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),

  on(LaunchpointDynamicFormResponseActions.getLaunchpointDynamicFormResponseByIdSetId, (state, { data_id }) => ({
    ...state,
    selected_dynamic_form_response_id: data_id,
  })),
  on(LaunchpointDynamicFormResponseActions.createLaunchpointDynamicFormResponse, (state, { data }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(LaunchpointDynamicFormResponseActions.createLaunchpointDynamicFormResponseSuccess, (state, { data, success_message }) => {
    const entity: LaunchpointDynamicFormResponseEntityState = {
      dynamic_form_response_id: data._id,
      dynamic_form_response: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return LaunchpointDynamicFormResponseEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      error: null,
      success_message: success_message ?? null,
      selected_dynamic_form_response_id: data._id,
    });
  }),
  on(LaunchpointDynamicFormResponseActions.createLaunchpointDynamicFormResponseFailure, (state, { error }) => ({
    ...state,
    loading: true,
    error: error,
  })),

  on(LaunchpointDynamicFormResponseActions.updateLaunchpointDynamicFormResponse, (state, { data }) => {
    return LaunchpointDynamicFormResponseEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(LaunchpointDynamicFormResponseActions.updateLaunchpointDynamicFormResponseSuccess, (state, { data, success_message }) => {
    return LaunchpointDynamicFormResponseEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          dynamic_form_response: data,
          loading: false,
          success_message,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(LaunchpointDynamicFormResponseActions.updateLaunchpointDynamicFormResponseFailure, (state, { data_id, error }) => {
    return LaunchpointDynamicFormResponseEntityAdaptor.updateOne(
      {
        id: data_id,
        changes: {
          error,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(LaunchpointDynamicFormResponseActions.deleteLaunchpointDynamicFormResponse, (state, { data }) => {
    return LaunchpointDynamicFormResponseEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loading: true,
          loaded: false,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(LaunchpointDynamicFormResponseActions.deleteLaunchpointDynamicFormResponseSuccess, (state, { data, success_message }) => {
    // remove the deleted response from the state

    // create a new array of years including the year of the deleted response so they can refill it, and sort it in descending order
    const lowest_year = Math.min(...state.years);
    const max_year = Math.max(...state.years);
    const years = Array.from({ length: max_year - lowest_year + 1 }, (_, index) => lowest_year + index).sort((a, b) => b - a);

    return LaunchpointDynamicFormResponseEntityAdaptor.removeOne(data._id, {
      ...state,
      years,
      loading: false,
      loaded: true,
      success_message: success_message ?? null,
    });
  }),
  on(LaunchpointDynamicFormResponseActions.deleteLaunchpointDynamicFormResponseFailure, (state, { data_id, error }) => {
    return LaunchpointDynamicFormResponseEntityAdaptor.updateOne(
      {
        id: data_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(LaunchpointDynamicFormResponseActions.closeLaunchpointDynamicFormResponseSuccessMessage, (state) => ({
    ...state,
    success_message: null,
  })),
  // CLOSE ERROR MESSAGE
  on(LaunchpointDynamicFormResponseActions.closeLaunchpointDynamicFormResponseErrorMessage, (state) => ({
    ...state,
    // error: null,
  }))
);
