import { ILaunchpointDynamicForm } from '@launchpoint-app/types';
import { IHttpException } from '@launchpoint/core-types';

export interface LaunchpointDynamicFormEntityState {
  dynamic_form_id: string;
  dynamic_form: ILaunchpointDynamicForm | null;
  selected_group_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message?: string | null;
  // ADD MORE CUSTOME PER FEATURE DATA HERE
}
