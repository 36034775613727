import { afterNextRender, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { isPlatformBrowser, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter, takeUntil } from 'rxjs/operators';
import { AppBaseComponent } from '@launchpoint/core-web';

@Component({
  selector: 'sd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent extends AppBaseComponent implements OnInit {
  location: any;
  routerSubscription: any;

  private isBrowser: boolean;
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    super();
    afterNextRender(() => {
      this.isBrowser = true;
    });
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    this.recallJsFuntions();
  }

  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        if (this.isBrowser) {
          window.scrollTo(0, 0);
        }
      });
  }
}
