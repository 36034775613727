import {
  ILaunchpointDynamicFormResponse,
  ILaunchpointDynamicFormResponseParamsArchive,
  ILaunchpointDynamicFormResponseParamsArchiveBulk,
  ILaunchpointDynamicFormResponseParamsCreate,
  ILaunchpointDynamicFormResponseParamsDelete,
  ILaunchpointDynamicFormResponseParamsUpdate,
  ILaunchpointDynamicFormResponseParamsUpdateBulk,
  ILaunchpointDynamicFormResponseSearchQuery,
  ILaunchpointDynamicFormResponseSearchResults,
} from '@launchpoint-app/types';
import {
  IHttpException,
  IQueryArchiveBulkResult,
  IQueryDeleteBulkResult,
  IQueryPageination,
  IQuerySort,
  IQueryUpdateBulkResult,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllLaunchpointDynamicFormResponse = createAction('[f-LaunchpointDynamicFormResponse] searchAllLaunchpointDynamicFormResponse');
export const searchAllLaunchpointDynamicFormResponseSuccess = createAction(
  '[f-LaunchpointDynamicFormResponse] searchAllLaunchpointDynamicFormResponseSuccess',
  props<{ data: ILaunchpointDynamicFormResponseSearchResults; success_message?: string }>()
);

export const searchAllLaunchpointDynamicFormResponseFailure = createAction(
  '[f-LaunchpointDynamicFormResponse] searchAllLaunchpointDynamicFormResponseFailure',
  props<{ error: IHttpException }>()
);

// Mechanics
export const updateAllLaunchpointDynamicFormResponsePagination = createAction(
  '[f-LaunchpointDynamicFormResponse] updateAllLaunchpointDynamicFormResponsePagination',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllLaunchpointDynamicFormResponsePaginationScroll = createAction(
  '[f-LaunchpointDynamicFormResponse] updateAllLaunchpointDynamicFormResponsePaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllLaunchpointDynamicFormResponseSorting = createAction(
  '[f-LaunchpointDynamicFormResponse] updateAllLaunchpointDynamicFormResponseSorting',
  props<{ querySort: IQuerySort }>()
);
export const updateAllLaunchpointDynamicFormResponseQuery = createAction(
  '[f-LaunchpointDynamicFormResponse] updateAllLaunchpointDynamicFormResponseQuery',
  props<{ query: ILaunchpointDynamicFormResponseSearchQuery }>()
);

// getById
export const getLaunchpointDynamicFormResponseById = createAction(
  '[f-LaunchpointDynamicFormResponse] getLaunchpointDynamicFormResponseById',
  props<{ data_id: string }>()
);
export const getLaunchpointDynamicFormResponseByIdSuccess = createAction(
  '[f-LaunchpointDynamicFormResponse] getLaunchpointDynamicFormResponseByIdSuccess',
  props<{ data: ILaunchpointDynamicFormResponse; success_message?: string }>()
);
export const getLaunchpointDynamicFormResponseByIdFailure = createAction(
  '[f-LaunchpointDynamicFormResponse] getLaunchpointDynamicFormResponseByIdFailure',
  props<{ data_id: string; error: IHttpException }>()
);

export const getLaunchpointDynamicFormResponseByIdSetId = createAction(
  '[f-LaunchpointDynamicFormResponse] getLaunchpointDynamicFormResponseByIdSetId',
  props<{ data_id: string }>()
);

// CREATE
export const createLaunchpointDynamicFormResponse = createAction(
  '[f-LaunchpointDynamicFormResponse] createLaunchpointDynamicFormResponse',
  props<{ data: ILaunchpointDynamicFormResponseParamsCreate }>()
);
export const createLaunchpointDynamicFormResponseSuccess = createAction(
  '[f-LaunchpointDynamicFormResponse] createLaunchpointDynamicFormResponseSuccess',
  props<{ data: ILaunchpointDynamicFormResponse; success_message?: string }>()
);
export const createLaunchpointDynamicFormResponseFailure = createAction(
  '[f-LaunchpointDynamicFormResponse] createLaunchpointDynamicFormResponseFailure',
  props<{ error: IHttpException }>()
);

// UPDATE
export const updateLaunchpointDynamicFormResponse = createAction(
  '[f-LaunchpointDynamicFormResponse] updateLaunchpointDynamicFormResponse',
  props<{ data: ILaunchpointDynamicFormResponseParamsUpdate }>()
);
export const updateLaunchpointDynamicFormResponseSuccess = createAction(
  '[f-LaunchpointDynamicFormResponse] updateLaunchpointDynamicFormResponseSuccess',
  props<{ data: ILaunchpointDynamicFormResponse; success_message?: string }>()
);
export const updateLaunchpointDynamicFormResponseFailure = createAction(
  '[f-LaunchpointDynamicFormResponse] updateLaunchpointDynamicFormResponseFailure',
  props<{ data_id: string; error: IHttpException }>()
);

// UPDATE BULK
export const updateLaunchpointDynamicFormResponseBulk = createAction(
  '[f-LaunchpointDynamicFormResponseBulk] updateLaunchpointDynamicFormResponseBulk',
  props<{ data: ILaunchpointDynamicFormResponseParamsUpdateBulk }>()
);
export const updateLaunchpointDynamicFormResponseSuccessBulk = createAction(
  '[f-LaunchpointDynamicFormResponse] updateLaunchpointDynamicFormResponseSuccessBulk',
  props<{ data: IQueryUpdateBulkResult; success_message?: string }>()
);
export const updateLaunchpointDynamicFormResponseFailureBulk = createAction(
  '[f-LaunchpointDynamicFormResponse] updateLaunchpointDynamicFormResponseFailureBulk',
  props<{ data_id: string; error: IHttpException }>()
);

// ARCHIVE
export const archiveLaunchpointDynamicFormResponse = createAction(
  '[f-LaunchpointDynamicFormResponse] archiveLaunchpointDynamicFormResponse',
  props<{ data: ILaunchpointDynamicFormResponseParamsArchive }>()
);
export const archiveLaunchpointDynamicFormResponseSuccess = createAction(
  '[f-LaunchpointDynamicFormResponse] archiveLaunchpointDynamicFormResponseSuccess',
  props<{ data: IQueryArchiveBulkResult; success_message?: string }>()
);
export const archiveLaunchpointDynamicFormResponseFailure = createAction(
  '[f-LaunchpointDynamicFormResponse] archiveLaunchpointDynamicFormResponseFailure',
  props<{ data_id: string; error: IHttpException }>()
);

// ARCHIVE BULK
export const archiveLaunchpointDynamicFormResponseBulk = createAction(
  '[f-LaunchpointDynamicFormResponseBulk] archiveLaunchpointDynamicFormResponseBulk',
  props<{ data: ILaunchpointDynamicFormResponseParamsArchiveBulk }>()
);
export const archiveLaunchpointDynamicFormResponseSuccessBulk = createAction(
  '[f-LaunchpointDynamicFormResponse] archiveLaunchpointDynamicFormResponseSuccessBulk',
  props<{ data: IQueryArchiveBulkResult; success_message?: string }>()
);
export const archiveLaunchpointDynamicFormResponseFailureBulk = createAction(
  '[f-LaunchpointDynamicFormResponse] archiveLaunchpointDynamicFormResponseFailureBulk',
  props<{ data_id: string; error: IHttpException }>()
);

// DELETE
export const deleteLaunchpointDynamicFormResponse = createAction(
  '[f-LaunchpointDynamicFormResponse] deleteLaunchpointDynamicFormResponse',
  props<{ data: ILaunchpointDynamicFormResponseParamsDelete }>()
);
export const deleteLaunchpointDynamicFormResponseSuccess = createAction(
  '[f-LaunchpointDynamicFormResponse] deleteLaunchpointDynamicFormResponseSuccess',
  props<{ data: ILaunchpointDynamicFormResponse; success_message?: string }>()
);
export const deleteLaunchpointDynamicFormResponseFailure = createAction(
  '[f-LaunchpointDynamicFormResponse] deleteLaunchpointDynamicFormResponseFailure',
  props<{ data_id: string; error: IHttpException }>()
);

// DELETE BULK
export const deleteLaunchpointDynamicFormResponseBulk = createAction(
  '[f-LaunchpointDynamicFormResponse] deleteLaunchpointDynamicFormResponseBulk',
  props<{ data: ILaunchpointDynamicFormResponseParamsDelete }>()
);
export const deleteLaunchpointDynamicFormResponseSuccessBulk = createAction(
  '[f-LaunchpointDynamicFormResponse] deleteLaunchpointDynamicFormResponseSuccessBulk',
  props<{ data: IQueryDeleteBulkResult; success_message?: string }>()
);
export const deleteLaunchpointDynamicFormResponseFailureBulk = createAction(
  '[f-LaunchpointDynamicFormResponse] deleteLaunchpointDynamicFormResponseFailureBulk',
  props<{ data_id: string; error: IHttpException }>()
);

/**
 * CLOSE ALERT MESSAGES
 */
export const closeLaunchpointDynamicFormResponseSuccessMessage = createAction(
  '[f-LaunchpointDynamicFormResponse] closeLaunchpointDynamicFormResponseSuccessMessage'
);

export const closeLaunchpointDynamicFormResponseErrorMessage = createAction(
  '[f-LaunchpointDynamicFormResponse] closeLaunchpointDynamicFormResponseErrorMessage'
);
