import { ELaunchpointFormStatus } from '../dynamic-forms.constants';
import { ILaunchpointDynamicFormGroup } from './groups/form-groups.interface';
import { IsArray, ArrayNotEmpty, IsString, IsNotEmpty, ValidateNested, IsEnum, IsOptional } from 'class-validator';
import { Type as TypeTransformer } from 'class-transformer';
import { ILaunchpointDynamicFormComputedField } from './computed-fields/computed-fields.interface';
import { IQueryPageination, IQuerySort } from '@launchpoint/core-types';
export const LAUNCHPOINT_DYNAMIC_FORMS_SCHEMA = 'LAUNCHPOINT_DYNAMIC_FORMS_SCHEMA';

/**
 * Represents the structure of a form, including its status, version, and groups of questions.
 */
export class ILaunchpointDynamicFormBase {
  _id?: string;
  /** The publication status of the form.
   * @default ELaunchpointFormStatus.DRAFT
   */
  status: ELaunchpointFormStatus;
  /** The name of the form. */
  form_name: string;
  /** The version number of the form. */
  form_version: number;
  /** An array of groups that compose the form. */
  groups: ILaunchpointDynamicFormGroup[];
  /**
   * fields created from computed answers of other fields.
   */
  computed_fields?: ILaunchpointDynamicFormComputedField[];
  // TODO: Validation Theory?
  // validation: [{
  //   question_id: string, // Year
  //   validations: {
  //     unique: boolean //Unqiue Per User
  //   }
  // }]
  /** Timestamp of when the form was created. */
  created_at?: Date;
  /** Timestamp of the last update to the form. */
  updated_at?: Date;
  /** Array of IDs referencing the associated account(s) for this response. */
  accounts?: string[];
  /** Array of IDs referencing the associated user(s) for this response. */
  users?: string[];
}

/**
 * Represents the structure of a form, including its status, version, and groups of questions.
 */
export interface ILaunchpointDynamicForm extends ILaunchpointDynamicFormBase {
  status: ELaunchpointFormStatus;
  form_name: string;
  form_version: number;
  groups: ILaunchpointDynamicFormGroup[];
  computed_fields: ILaunchpointDynamicFormComputedField[];
  created_at?: Date;
  updated_at?: Date;
}

export class ILaunchpointDynamicFormParamsCreate
  implements Partial<Pick<ILaunchpointDynamicFormBase, 'accounts' | 'users' | 'form_name' | 'status' | 'created_at' | 'updated_at'>>
{
  @IsOptional()
  @IsArray({ message: 'Updates must be an array' })
  @ArrayNotEmpty({ message: 'Updates array cannot be empty' })
  accounts?: string[];
  @IsOptional()
  @IsArray({ message: 'Updates must be an array' })
  @ArrayNotEmpty({ message: 'Updates array cannot be empty' })
  users?: string[];
  @IsString({ message: 'form_name must be a string and is required' })
  form_name!: string;
  @IsOptional()
  @IsEnum(ELaunchpointFormStatus, { message: 'Status must be a valid ELaunchpointFormStatus enum value' })
  status?: ELaunchpointFormStatus;
  @IsOptional()
  created_at?: Date;
  @IsOptional()
  updated_at?: Date;
}

export class ILaunchpointDynamicFormParamsUpdate
  implements Partial<Pick<ILaunchpointDynamicFormBase, '_id' | 'accounts' | 'users' | 'form_name' | 'status' | 'created_at' | 'updated_at'>>
{
  @IsString({ message: '_id must be a string' })
  _id!: string;

  /**
   * Should we allow this updating of the entire users array and accounts array on this route?
   */
  @IsOptional()
  @IsArray({ message: 'Updates must be an array' })
  @ArrayNotEmpty({ message: 'Updates array cannot be empty' })
  accounts?: string[];
  @IsOptional()
  @IsArray({ message: 'Updates must be an array' })
  @ArrayNotEmpty({ message: 'Updates array cannot be empty' })
  users?: string[];
  @IsString({ message: 'form_name must be a string and is required' })
  form_name!: string;
  @IsOptional()
  @IsEnum(ELaunchpointFormStatus, { message: 'Status must be a valid ELaunchpointFormStatus enum value' })
  status?: ELaunchpointFormStatus;
  @IsOptional()
  created_at?: Date;
  @IsOptional()
  updated_at?: Date;
}

export class ILaunchpointDynamicFormParamsUpdateBulk {
  @IsArray({ message: 'Updates must be an array' })
  @ArrayNotEmpty({ message: 'Updates array cannot be empty' })
  @ValidateNested({ each: true, message: 'Each update must be a valid ILaunchpointDynamicFormParamsUpdate or ILaunchpointDynamicFormParamsCreate' })
  @TypeTransformer(() => ILaunchpointDynamicFormParamsUpdate) // Use the correct type for your logic
  updates: ILaunchpointDynamicFormParamsUpdate[] | ILaunchpointDynamicFormParamsCreate[];
}

export class ILaunchpointDynamicFormSearchQuery {
  @IsOptional()
  @IsString({ message: 'Search must be a string' })
  search?: string;

  @IsOptional()
  @IsArray({ message: 'Status must be an array of ELaunchpointFormStatus enum values' })
  @IsEnum(ELaunchpointFormStatus, { each: true, message: 'Each status must be a valid ELaunchpointFormStatus enum value' })
  status?: ELaunchpointFormStatus[];
}
/**
 * SEARCH PAYLOAD
 *
 * This is the required payload to run a search query and return values
 */
export class ILaunchpointDynamicFormSearchPayload {
  @ValidateNested()
  @TypeTransformer(() => ILaunchpointDynamicFormSearchQuery)
  query: ILaunchpointDynamicFormSearchQuery;

  @ValidateNested()
  @TypeTransformer(() => IQueryPageination)
  pagination: IQueryPageination;

  @IsOptional()
  querySort?: IQuerySort;
}

/**
 * OPTIONAL SEARCH RESULTS OVERRIDE //:LINK
 *
 * This is the payload for the search query results.
 */
// export type ILaunchpointDynamicFormData = ILaunchpointDynamicForm & {
//   distance: number;
//   location: { type: string; coordinates: [number, number] };
// };

export class ILaunchpointDynamicFormSearchResults {
  pagination: IQueryPageination;
  data: ILaunchpointDynamicForm[]; // OR ILaunchpointDynamicFormData[]; //:LINK
}

export class ILaunchpointDynamicFormParamsDelete {
  @IsString({ message: '_id must be a string' })
  @IsNotEmpty({ message: '_id cannot be empty' })
  _id: string;
}

export class ILaunchpointDynamicFormParamsDeleteBulk {
  @IsArray({ message: '_ids must be an array' })
  @ArrayNotEmpty({ message: '_ids array cannot be empty' })
  @IsString({ each: true, message: 'Each _id in the _ids array must be a string' })
  _ids: string[];
}

export class ILaunchpointDynamicFormParamsArchive {
  @IsString({ message: '_id must be a string' })
  @IsNotEmpty({ message: '_id cannot be empty' })
  _id: string;
}

export class ILaunchpointDynamicFormParamsArchiveBulk {
  @IsArray({ message: '_ids must be an array' })
  @ArrayNotEmpty({ message: '_ids array cannot be empty' })
  @IsString({ each: true, message: 'Each _id in the _ids array must be a string' })
  _ids: string[];
}
