import { isPlatformBrowser } from '@angular/common';
import { afterNextRender, AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';

@Component({
  selector: 'sd-for-businesses',
  templateUrl: './for-businesses.component.html',
  styleUrl: './for-businesses.component.scss',
})
export class ForBusinessesComponent implements AfterViewInit {
  private isBrowser: boolean;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    afterNextRender(() => {
      this.isBrowser = true;
    });
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit(): void {
    const element = this.el.nativeElement.querySelector('.fade-in');

    if (this.isBrowser) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.renderer.addClass(element, 'visible');
            }
          });
        },
        {
          threshold: 0.4,
        }
      );

      observer.observe(element);
    }
  }
}
